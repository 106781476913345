class UserOrganizationStorageAPI {
    /**
     * Caching the value here, because otherwise we get issues where
     * when the user has multiple tabs open.
     **/
    protected organizationId: null | string;
    protected storage?: Storage;
    protected key: string;

    constructor({
        key = '42-organization',
        storage = globalThis.localStorage,
    }: { key?: string; storage?: Storage } = {}) {
        this.organizationId = null;
        this.storage = storage;
        this.key = key;
    }

    get(): string | null {
        if (this.organizationId) {
            return this.organizationId;
        }
        if (!globalThis.localStorage) {
            console.error('No localStorage available, cannot get organization.');
            return null;
        }
        try {
            return globalThis.localStorage.getItem(this.key) ?? null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async set(organizationId: string) {
        if (!globalThis.localStorage) {
            console.error('No localStorage available, cannot set organization.');
            return;
        }
        try {
            globalThis.localStorage.setItem(this.key, organizationId);
        } catch (error: any) {
            if (error.name === 'QuotaExceededError') {
                console.error('LocalStorage length:', localStorage?.length);
            }
            console.error(error);
        }
    }
}

export const UserOrganizationStorage = new UserOrganizationStorageAPI();
