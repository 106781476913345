{ ResizeObserver } = require '@juggle/resize-observer'
moment             = require 'moment'

_              = require('../../lib/lodash-helper.ts')
AuthServiceAPI = require('../../lib/auth')


module = angular.module('42.controllers.sales', ['42.filters'])

module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'sales'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route

module.controller 'SalesController', ($q, $scope, $rootScope, CONFIG, TimeSpan, SaleCharts, SalesChartGridConfig, SalesTopItemsConfigService, TopItemsViewModel) ->
    $scope.itemsUrl = $rootScope.routes.items.url
    $scope.topItems = undefined

    $scope.showTopItems = do ->
        flag = CONFIG.flags?.showTopItems
        return _.isUndefined(flag) or flag

    $scope.showLegacySalesChart = false

    refreshTimeSpan = ->
        TimeSpan.fetch($rootScope.query).then (timespan) ->
            $scope.timespan = timespan

    refreshCharts = ->
        $q.all([
            SaleCharts.fetch(),
            SalesTopItemsConfigService.fetch()
        ]).then ([charts, topItemsWidgetConfig]) ->
            $scope.charts = charts
            $scope.selectedChart ?= charts[0]
            $scope.topItemsWidgetsConfig = topItemsWidgetConfig
            refreshWidgets()

    refreshChartGrid = ->
        SalesChartGridConfig.fetch().then (chartGrid) ->
            $scope.chartGrid = chartGrid

    refresh = ->
        refreshTimeSpan()
        refreshChartGrid()

        if ($scope.showTopItems)
            refreshCharts()

    refreshWidgets = ->
        if not $scope.selectedChart
            $scope.topItems = undefined
            return

        $scope.topItems = $scope.topItemsWidgetsConfig.map (config) ->
            sort = config.sort or [{field: $scope.selectedChart.property, headerGroup: $scope.selectedChart.title}]
            config = {...config, sort}
            return new TopItemsViewModel(config, $rootScope.query)

    $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        $scope.$on '$destroy', $rootScope.$on('query.refresh', refresh)
        refreshTimeSpan()
        refreshChartGrid()

        if ($scope.showTopItems)
            refreshCharts()

    $scope.$watch 'selectedChart', -> refreshWidgets()

module.factory 'BudgetChartData', ($q, QueryServiceAPI) -> (metric) -> fetch: (query, canceler) -> (new QueryServiceAPI).then (api) ->
    $q.all([
      api.query.segmentCustomersByTransactions_comparison(query, canceler.promise)
      api.query.budgetReport_comparison(query)
    ]).then (data) ->
        [sales, budget] = [data[0][0], data[1][0]]
        return if _.isUndefined(sales[0].net_sales)
        return [
            budget.map (x) ->
                result = {}
                result.timestamp = x.timestamp
                result[metric] = x[metric]
                return result
            sales.map (x) ->
                result = {}
                result.timestamp = x.timestamp
                result[metric] = do ->
                    return x.net_sales_without_taxes if 'net_sales_without_taxes' of x
                    return x.net_sales
                return result
        ]


module.service 'ChartGridConfigService', ($rootScope, $q, CONFIG, StorageAPI) ->

    getFromOrgConfig = -> $q.when do ->
        return CONFIG.views?.sales?.chartGrid

    getFromUserConfig = ->
        (new StorageAPI 'views').then((api) -> api.get()).then (views) ->
            return views?.sales?.chartGrid

    fetch: ->
        $q.all([
            getFromOrgConfig()
            getFromUserConfig()
        ]).then ([orgConfig, userConfig]) ->
            config = userConfig or orgConfig or []
            return config if _.isArray(config)
            hierarchyId = $rootScope.hierarchyModel?.selected?.id
            return config[hierarchyId] or []


module.service 'SalesTopItemsConfigService', ($q, CONFIG, Hierarchy, QueryMetrics, SalesChartMetricsConfigService) ->

    fetchHierarchy = ->
        Hierarchy.fetch().then ({all, stores, items}) ->
            return _.keyBy(_.flatten([stores, items, all].map (x) -> Object.values(x)), 'id')

    joinMetricIdsWithMetricDescriptors = (availableMetrics, selectedMetrics) ->
        metrics = _.keyBy availableMetrics, (x) -> x.field
        return _.compact selectedMetrics.map (fieldOrDescriptor) ->
            selectedMetric = {field: fieldOrDescriptor} if _.isString(fieldOrDescriptor)
            if not metrics[selectedMetric.field]
                console.error "Top Item metric `#{selectedMetric.field}` not found."
                return null
            metric = _.cloneDeep(metrics[selectedMetric.field])
            metric.inverted = (metrics["growth_#{selectedMetric.field}"] or metric)?.cellClass is 'percent-inverted'
            return _.extend(metric, selectedMetric)

    joinPropertiesWithHierarchy = (availableProperties, properties) ->
        properties = [properties] if typeof properties is 'string'
        return properties.map (propertyIdOrProperty) ->
            return propertyIdOrProperty if typeof propertyIdOrProperty isnt 'string'
            property = availableProperties[propertyIdOrProperty] or { id: propertyIdOrProperty }
            label = property.plural or property.label or property.id
            return {
                id: property.id,
                label
            }

    fetchUserConfig = ->
        config = _.cloneDeep(CONFIG.views?.sales?.topItems)
        return [] if not config
        return [config] if _.isPlainObject(config) and config.kpis
        if Array.isArray(config)
            return config.reduce ((acc, topItemsConfigItem) ->
                if !_.isEmpty(topItemsConfigItem)
                    acc.push(topItemsConfigItem)
                return acc
            ), []
        console.warn("Invalid type for views.sales.topItems:", config)
        return []

    fetchDefaultProperties = ->
        property = CONFIG.defaults?.items?.itemsGroupBy or 'items.name'
        return [property]

    fetchDefaultKpis = (salesChartMetrics, availableMetrics) ->
        itemsKpis = do ->
            return null if not Array.isArray(salesChartMetrics)
            return salesChartMetrics
        salesKpis = do ->
            config = CONFIG.views?.sales?.kpis or []
            config = joinMetricIdsWithMetricDescriptors(availableMetrics, config)
            config = config.filter (x) -> ['Sales', 'Inventory', 'Demand'].includes(x.category)
            return config
        kpis = itemsKpis or salesKpis
        kpis = kpis.slice(0, 6)
        kpis = kpis.map (x) -> x.field
        return kpis

    fetchDefaultConfig = (salesChartMetrics, availableMetrics) ->
        return [normalizeConfig(salesChartMetrics, availableMetrics, {})]

    normalizeConfig = (salesChartMetrics, availableMetrics, config = {}) ->
        config.kpis ?= fetchDefaultKpis(salesChartMetrics, availableMetrics)
        config.properties = do ->
            properties = config.properties or config.property
            properties = [properties] if not Array.isArray(properties)
            properties = _.compact(properties)
            return null if properties.length is 0
            return properties
        config.properties ?= fetchDefaultProperties()
        config.sort = do ->
            return null if not config.sort
            return config.sort if Array.isArray(config.sort)
            return [config.sort] if typeof config.sort is 'string'
            console.warn("Invalid sort value for top items:", config)
            return null
        return config

    fetch = ->
        $q.all([
            QueryMetrics.fetch(),
            fetchHierarchy(),
            SalesChartMetricsConfigService.fetch()
        ]).then ([availableMetrics, availableProperties, salesChartMetrics]) ->
            configs = do ->
                configs = fetchUserConfig()
                return fetchDefaultConfig(salesChartMetrics, availableMetrics) if not configs or configs?.length is 0

                configs.forEach (topItemConfigItem) ->
                    if !topItemConfigItem.kpis or topItemConfigItem.kpis.length is 0
                        topItemConfigItem.kpis = fetchDefaultKpis(salesChartMetrics, availableMetrics)

                return configs

            return configs.map (config) ->
                config.properties = do ->
                    propertiesConfig = config.properties or config.property
                    propertiesConfig ?= fetchDefaultProperties()
                    return null if not config
                    return joinPropertiesWithHierarchy(availableProperties, propertiesConfig)
                config.sort = do ->
                    return null if not config.sort
                    sortMetrics = joinMetricIdsWithMetricDescriptors(availableMetrics, config.sort)
                    return QueryMetrics.applyCurrencyToMetrics(sortMetrics)
                config.metrics = do ->
                    metrics = joinMetricIdsWithMetricDescriptors(availableMetrics, config.kpis)
                    metrics = QueryMetrics.applyCurrencyToMetrics(metrics)
                    return metrics.reduce ((acc, metricData) ->
                        acc[metricData.field] = metricData
                        return acc
                    ), {}

                return config

    return {fetch}


module.factory 'SalesChartGridConfig', (ChartGridConfigService) ->
    promise = null
    fetch: ->
        promise ?= ChartGridConfigService.fetch()
        promise.then (x) -> _.cloneDeep(x)


module.factory 'SalesChartMetricsConfig', (SalesChartMetricsConfigService) ->
    promise = null
    fetch: ->
        promise ?= SalesChartMetricsConfigService.fetch()
        promise.then (x) -> _.cloneDeep(x)


module.service 'SalesChartMetricsConfigService', ($q, StorageAPI, CONFIG, QueryMetrics) ->

    DEFAULT_METRICS = [
        "net_sales"
        "net_sales_units"
        "average_net_sales"
        "gross_dollar_per_transaction"
        "transaction_count"
        "gross_sales_units_per_transaction"
        "returned_sales_as_of_gross_sales"
        "on_hands_units"
    ]

    getSelectedMetricsFromOrgConfig = -> $q.when do ->
        return CONFIG.views?.sales?.kpis

    getSelectedMetricsFromUserConfig = ->
        (new StorageAPI 'views').then((api) -> api.get()).then (views) ->
            return views?.sales?.kpis

    getSelectedMetrics = -> $q.all([
        getSelectedMetricsFromOrgConfig()
        getSelectedMetricsFromUserConfig()
    ]).then ([orgMetrics, userMetrics]) ->
        return userMetrics or orgMetrics or DEFAULT_METRICS

    process = (availableMetrics, selectedMetrics) ->
        metrics = _.keyBy availableMetrics, (x) -> x.field
        return _.compact selectedMetrics.map (x) ->
            x = {field:x} if _.isString(x)
            if not metrics[x.field]
                console.error "Sales page metric `#{x.field}` not found."
                return null
            metric = _.cloneDeep(metrics[x.field])
            metric.inverted = (metrics["growth_#{x.field}"] or metric)?.cellClass is 'percent-inverted'
            return _.extend(metric, x)

    fetch: ->
        $q.all([
            QueryMetrics.fetch()
            getSelectedMetrics()
        ]).then ([availableMetrics, selectedMetrics]) ->
            metrics = process(availableMetrics, selectedMetrics)
            metrics = QueryMetrics.applyCurrencyToMetrics(metrics)
            return metrics

module.service 'SaleCharts', ($rootScope, $q, $timeout, $filter, Utils, CONFIG, SalesChartMetricsConfig, QueryServiceAPI, promiseTracker, BudgetChartData, QueryMetrics) ->
    fetch: -> $q.all([new QueryServiceAPI(), SalesChartMetricsConfig.fetch(), AuthServiceAPI.getOrganization(), AuthServiceAPI.getUser(), QueryMetrics.fetch()]).then ([api, salesChartMetrics, organization, user, allMetrics]) ->

        allMetricsData = allMetrics.reduce((acc, metric) ->
            acc[metric.field] = metric

            return acc
        , {})

        timeseriesChart = ({title, alignment, property, growth_value_prefix, options, type, inverted, metricData}) ->
            title: title
            type:  type
            property: property
            alignment: alignment
            growth_value_prefix: growth_value_prefix
            options: options
            inverted: inverted
            metricData: metricData
            tracker: promiseTracker("sales-chart-#{title}")
            query: (query) ->
                query = _.cloneDeep($rootScope.query)

                canceler = $q.defer()
                apiCall = do ->
                    return api.query.inventorySalesReport_comparison if property in ['on_hands', 'on_hands_units', 'on_hands_cost']
                    return api.query.trafficReport_comparison        if property in ['traffic', 'conversion']
                    return api.query.onlineTrafficReport_comparison  if property in ['visits', 'online_conversion']
                    return api.query.inventorySellThrough_comparison if property in ['sellthru_percentage']
                    return api.query.demandReport_comparison         if property in ['demand_net_sales', 'demand_open_net_sales', 'demand_invoiced_net_sales', 'demand_units', 'demand_open_units', 'demand_invoiced_units']
                    return BudgetChartData('budget').fetch           if property is 'budget'
                    return BudgetChartData('latest_estimate').fetch  if property is 'latest_estimate'
                    return api.query.segmentCustomersByTransactions_comparison

                promise = \
                apiCall(query, canceler.promise)
                .then (data) ->
                    isEmpty = do ->
                        result = try (data.length is 1) and (data[0].length is 1) and Object.keys(data[0][0]).length is 1
                        return _.isBoolean(result) and result
                    return null if isEmpty
                    data.map (series) -> series.map (d) ->
                        timestamp: d.timestamp
                        data:      parseFloat d[property]
                promise.cancel = ->
                    return if @apiCall is apiCall
                    canceler.resolve()
                promise.apiCall = apiCall
                return promise

        switch organization
            when 'allsaints', 'allsaints-new'
                return _.compact [
                    timeseriesChart
                        title: 'Gross Sales'
                        property: 'net_sales'
                        metricData: allMetricsData['net_sales']
                        type: 'money'

                    timeseriesChart
                        title: 'Nett Sales'
                        property: 'net_sales_without_taxes'
                        metricData: allMetricsData['net_sales_without_taxes']
                        type: 'money'

                    timeseriesChart
                        title: 'Gross Sales Excl. Returns'
                        property: 'gross_sales'
                        metricData: allMetricsData['gross_sales']
                        type: 'money'

                    timeseriesChart
                        title: 'Gross Return'
                        property: 'returned_sales'
                        metricData: allMetricsData['returned_sales']
                        type: 'money'
                        inverted: true

                    if $rootScope.flags.showBudget or $rootScope.flags.showBudgets
                        timeseriesChart
                            title: 'Forecast'
                            property: 'budget'
                            metricData: allMetricsData['budget']
                            type: 'money'
                            alignment: false
                            growth_value_prefix: 'Net Sales'
                            options: do ->
                                labels = [
                                    {name:'Budget'}
                                    {name:'Net Sales'}
                                ]
                                colors: [
                                  '#D9BA5D'
                                  '#5DA5DA'
                                ]
                                plotOptions:
                                    grouping: false
                                    shadow: false
                                series: (dataset, seriesIndex) ->
                                    return labels[seriesIndex]
                                tooltip: pointFormatter: ->
                                  color = @color
                                  value = $filter('money')(@y)
                                  label = @series.name
                                  """
                                  <span style="color: #{color}">#{label}</span>: <b>#{value}</b><br/>
                                  """

                    timeseriesChart
                        title: 'Footfall'
                        property: 'traffic'
                        metricData: allMetricsData['traffic']
                        type: 'number:0'

                    timeseriesChart
                        title: 'Conversion'
                        property: 'conversion'
                        metricData: allMetricsData['conversion']
                        type: 'percent'

                    timeseriesChart
                        title: 'Transactions'
                        property: 'transaction_count'
                        metricData: allMetricsData['transaction_count']
                        type: 'number'

                    timeseriesChart
                        title: 'Gross Sales Units'
                        property: 'net_sales_units'
                        metricData: allMetricsData['net_sales_units']
                        type: 'number'

                    timeseriesChart
                        title: 'Gross UPT'
                        property: 'gross_sales_units_per_transaction'
                        metricData: allMetricsData['gross_sales_units_per_transaction']
                        type: 'number:2'

                    timeseriesChart
                        title: 'Gross ATV'
                        property: 'gross_dollar_per_transaction'
                        metricData: allMetricsData['gross_dollar_per_transaction']
                        type: 'money'

                    if user.name isnt 'digital dashboard'
                        timeseriesChart
                            title: 'Gross Margin %'
                            property: 'net_sales_margin'
                            metricData: allMetricsData['net_sales_margin']
                            type: 'percent'
                ]
            else
                # FIXME: [DEV-1811] Hardcoded metrics
                netSalesMetric   = _.find(salesChartMetrics, {field: 'net_sales'})
                grossSalesMetric = _.find(salesChartMetrics, {field: 'gross_sales'})
                profitMetric     = _.find(salesChartMetrics, {field: 'profit'})
                return salesChartMetrics.map (metric) -> timeseriesChart do ->
                    options =
                        metricData: metric
                        title:    metric.headerGroup
                        property: metric.field
                        type:     metric.cellFilter
                        inverted: metric.inverted

                    # FIXME: [DEV-1811] Hardcoded metrics
                    if metric.field in ['budget', 'latest_estimate', 'budget_profit', 'store_budget', 'gross_sales_rof', 'gross_sales_plan']

                        associatedMetric = do ->
                            return profitMetric if metric.field is 'budget_profit'
                            return grossSalesMetric if _.includes(metric.field, 'gross_sales')
                            return netSalesMetric

                        associatedHeaderGroup = do ->
                            currencySymbol = $rootScope.currencyModel?.selected?.symbol or '$'
                            return associatedMetric?.headerGroup.replace(currencySymbol, '')

                        defaultObj =
                            alignment: false
                            growth_value_prefix: associatedHeaderGroup
                            options: do ->
                                labels = [
                                    {name: metric.headerGroup}
                                    {name: associatedHeaderGroup}
                                ]
                                colors: [
                                  '#D9BA5D'
                                  '#5DA5DA'
                                ]
                                plotOptions:
                                    grouping: false
                                    shadow: false
                                series: (dataset, seriesIndex) ->
                                    return labels[seriesIndex]
                                tooltip: pointFormatter: ->
                                    color = @color
                                    value = $filter('money')(@y)
                                    label = @series.name
                                    """
                                    <span style="color: #{color}">#{label}</span>: <b>#{value}</b><br/>
                                    """

                        options = _.extend {}, options, defaultObj

                    return options

module.directive 'bucketPicker', (CONFIG) ->
    restrict: 'E'
    scope:
        selected: '='
    replace: true
    template: \
    """
    <article class="bucket-picker">
        <ul>
            <li ng-repeat="bucket in buckets"
                ng-click="select(bucket)"
                ng-class="{active:isSelected(bucket)}"
            >{{ bucket.label }}</li>
        </ul>
    </article>
    """
    link: (scope, element) ->
        showMonthBucket = Boolean(CONFIG.flags?.showMonthBucket ? false)
        defaultBucket = CONFIG.defaults?.overviewBucket or 'week'

        scope.buckets = _.compact([
            {id:'day',     label: 'Day'}
            {id:'week',    label: 'Week'}
            {id:'month',   label: 'Month'} if showMonthBucket
            # {id:'quarter', label: 'Quarter'}
            # {id:'year',    label: 'Year'}
        ])

        scope.selectById = (id) ->
            selected = null
            for bucket in scope.buckets
                selected = bucket if bucket.id is id
            return if not selected
            scope.select(selected)

        scope.isSelected = (bucket) ->
            scope.selected.id is bucket.id

        scope.select = (bucket) ->
            scope.selected = bucket

        if showMonthBucket
            scope.selectById('month')
        else
            scope.selectById(defaultBucket)


module.service 'TopItems', (Utils, QueryServiceAPI, QueryMetrics) ->

    fetch: (query, property) ->
        property ?= 'items.id'
        query = Utils.copy(query)
        delete query.comparison
        query.options.properties = [property]
        query.limit = 6
        query.options.includeTotals = false
        QueryMetrics.fetch().then (metrics) ->
            query.options.metrics = metrics.map (metric) -> metric.field
            return (new QueryServiceAPI).then (api) -> api.query.metricsFunnel(query)

module.factory 'TopItemsViewModel', ($q, $filter, TopItems) ->
    return class TopItemsViewModel
        constructor: (config, query) ->
            @items = null
            @property = null
            @html = null
            @property = config.properties?[0]
            @title = @getTitle(config)
            @query = _.cloneDeep(query)
            @query.sort = @getSorting(config)

            @init(config)

        getSorting: (config) ->
            return config.sort.reduce ((acc, metric) ->
                acc[metric.field] = -1
                return acc
            ), {}

        getTitle: (config) ->
            sortingSize = config.sort.length - 1

            return config.sort.reduce ((acc, metric, index) ->
                acc += metric.headerGroup

                if index != sortingSize
                    acc += ', '

                return acc
            ), ''

        init: (config) ->
            { metrics } = config

            return $q.when(TopItems.fetch(@query, @property.id)).then (items) =>
                @items = items
                @html = items.map (item) -> ItemInfoCellRenderer $filter,
                    data: item
                    metrics: Object.keys(metrics).map (metricId) ->
                        return { value: item[metricId], ...metrics[metricId] }


module.directive 'topItems', () ->
    restrict: 'E'
    replace: true
    scope:
        itemsUrl: '='
        model: '='
    template: \
    """
    <div class="top-items card" ng-class="{loading: model.items === null, empty: model.items.length == 0 }">
        <div class="top-items-header">
            <div class="header-label">
                <h1 ng-if="model.property !== null">Top {{ model.property.label }} sorted by {{ model.title }}</span></h1>
            </div>
            <div class="more-info-label">
                <a ng-href="{{ '/#' + itemsUrl }}">click here to see more item info</a>
            </div>
        </div>
        <section class="items">
            <ul class="top-items-list" ng-class="{ 'collapsed': collapsed }">
                <li class="item-container" ng-repeat="item in model.html track by $index">
                    <div class="item" ng-bind-html="item"></div>
                </li>
            </ul>
        </section>
    </div>
    """
    link: (scope, element) ->
        TOP_ITEMS_MIN_WIDTH = 640
        scope.collapsed =  false

        resizeObserver = new ResizeObserver (topItemsSection) ->
            topItemsListElement = $(element).find('.top-items-list')[0]
            topItemsListElementWidth = topItemsListElement.offsetWidth or 0
            topItemsSectionWith = topItemsSection[0].contentRect?.width or 0

            scope.collapsed = topItemsSectionWith <= TOP_ITEMS_MIN_WIDTH

        resizeObserver.observe($(element).find('.items')[0])


ItemInfoCellRenderer = ($filter, item) ->
    metrics = item.metrics or []
    itemImageHtml = do ->
        if item.data.item_image
            return "<div class='item-image' style='background-image: url(\"#{item.data.item_image}\")'></div>"

        return "<div class='item-image'></div>"

    html = _.flatten [
        itemImageHtml,
        "<div class='item-name'>#{item.data.property0}</div>",
        "<div class='item-info'>"
            if metrics.length > 0 then _.flatten([
                "<div class='item-metrics'>"
                renderItemMetrics($filter, metrics)
                "</div>"
            ])
        "</div>"
    ]
    return _.compact(html).join('')


renderItemMetrics = ($filter, metrics) ->
    return "" if not metrics or metrics.length is 0
    groupedMetrics = _.groupBy metrics, (x) -> x.headerGroup
    orderedHeaderGroups = do ->
        seen = {}
        result = []
        metrics.forEach (x) -> result.push(x.headerGroup) if (not seen[x.headerGroup]) and (seen[x.headerGroup] = true)
        return result
    _.flatten orderedHeaderGroups.map (headerGroup) ->
        metrics = groupedMetrics[headerGroup]
        isBare = metrics.length is 1
        _.flatten [
            "<span class='item-metric-group #{if isBare then 'bare' else ''}'>"
            "<span class='item-metric-header-group'>#{headerGroup}</span>"
            metrics.map (metric) ->
                value = metric.value
                value = "" if _.isNull(value) or _.isUndefined(value)
                if metric.cellFilter
                    [filter, filterArgs...] = metric.cellFilter.split(':')
                    value = $filter(filter)(value, filterArgs...)
                    value = "" if _.isUndefined(value) or _.isNull(value)
                value = value.toString?() or value if not _.isString(value)
                headerName = if metrics.length is 1 and metric.headerName is 'TY' then '' else metric.headerName
                hasPercentageCellClass = value.length > 0 and (metric.cellClass or "").indexOf('percent') isnt -1
                valueClass = do ->
                    if hasPercentageCellClass
                        rawValue = metric.value
                        rawValue = rawValue * -1 if metric.cellClass is 'percent-inverted'
                        return "percent-positive" if rawValue > 0
                        return "percent-negative" if rawValue < 0
                        return ""
                    if value.length is 0
                        return "blank"
                    return ""
                valueHTML = do ->
                    value = "n/a" if value.length is 0
                    # value = "<span class='perchevron'>#{value}</span>" if hasPercentageCellClass
                    return "<span class='item-metric-value #{valueClass}'>#{value}</span>"
                return _.compact([
                    """
                    <span class="item-metric">
                    """
                    if headerName then \
                    """
                    <span class="item-metric-header-name">#{headerName}</span>
                    """
                    """
                    #{valueHTML}
                    </span>
                    """
                ]).join('\n')
            "</span>"
        ]


module.directive 'cardOverallStats', ($q, $rootScope, promiseTracker, Utils, OverallStats) ->
    restrict: 'E'
    scope:
        charts: '='
        selectedChart: '='
    template: \
    """
    <div class="card stats overall-stats" promise-tracker="overall-stats" ng-class="{empty:isEmpty}">
        <ul>
            <li ng-repeat="stat in [].concat.apply([], stats)"
                ng-click="select(stat.chart)"
                ng-class="{selected:selectedChart.title == stat.title}">
                <div class="stats-content">
                    <div class="title"><span>{{ stat.title }}</span></div>
                    <div class="value" ng-class="{blank:stat.value === null}">{{ stat.value === null ? '–' : stat.value }}</div>
                    <div class="growth">
                        <span ng-hide="{{ stat.growth === null }}" ng-class="{'percent-invert-color': stat.inverted}" percent="{{ stat.growth }}">
                            {{ stat.growth | percent:true:false }}
                            (vs. {{ stat.growth_value_prefix }} {{ stat.growth_value === null ? '–' : stat.growth_value }})
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    """
    link: (scope, element) ->
        tracker = promiseTracker('overall-stats')
        $element = $(element)
        loadingPromise = $q.defer()
        tracker.addPromise(loadingPromise.promise)

        ROW_HEIGHT = 122
        MAX_ITEMS_PER_ROW = 5

        refresh = ->
            return if not scope.charts
            loadingPromise.resolve()
            updateHeight(scope.charts.length)
            promise = OverallStats.fetch(scope.charts, $rootScope.query).then (stats) ->
                updateHeight(stats.length)
                scope.stats = chunkStats(stats)
            tracker.addPromise(promise)
            return promise

        updateHeight = (itemCount) ->
            rowCount = Math.ceil(itemCount / MAX_ITEMS_PER_ROW)
            $element.css(height:(rowCount * ROW_HEIGHT))

        chunkStats = (stats) ->
            rowCount = Math.ceil(stats.length / MAX_ITEMS_PER_ROW)
            itemsPerRow = Math.ceil(stats.length / rowCount)
            return Utils.chunk(stats, itemsPerRow)

        scope.select = (chart) ->
            scope.selectedChart = chart

        $rootScope.$watch 'initialized', (initialized) ->
            return if not initialized
            scope.$watch 'charts', refresh
            scope.$on '$destroy', \
            $rootScope.$on 'query.refresh', refresh



module.service 'SalesChartGroupByProperty', (Utils, CONFIG, $rootScope, Hierarchy) ->

    fetch: -> Hierarchy.fetch().then (hierarchy) ->
        chartConfig = Utils.copy(CONFIG.views?.sales?.chart or {})
        hierarchyId = $rootScope.hierarchyModel?.selected?.id
        chartConfig.groupBy ?= chartConfig[hierarchyId]?.groupBy if hierarchyId
        chartConfig.groupBy ?= 'stores.aggregate'
        return chartConfig.groupBy


module.service 'OverallStats', (Utils, $q, $filter, promiseTracker, QueryMetrics, QueryServiceAPI, SalesChartGroupByProperty) ->

    # FIXME: [DEV-1811] Hardcoded metrics
    modifyBudgetData = (data) ->
        if _.has(data, 'budget_profit')
            data['growth_budget_profit'] = do ->
                return data['diff_to_budget_profit_percentage']
            data['growth_budget_profit_prev'] = do ->
                return data['profit']
        if _.has(data, 'latest_estimate')
            data['growth_latest_estimate_prev'] = do ->
                return data['net_sales_without_taxes'] if 'net_sales_without_taxes' of data
                return data['net_sales']
            data['growth_latest_estimate'] = do ->
                return data['diff_to_latest_estimate_percentage_without_taxes'] if 'diff_to_latest_estimate_percentage_without_taxes' of data
                return data['diff_to_latest_estimate_percentage']
        if _.has(data, 'budget')
            data['growth_budget_prev'] = do ->
                return data['net_sales_without_taxes'] if 'net_sales_without_taxes' of data
                return data['net_sales']
            data['growth_budget'] = do ->
                return data['diff_to_budget_percentage_without_taxes'] if 'diff_to_budget_percentage_without_taxes' of data
                return data['diff_to_budget_percentage']
        # frasers group
        if _.has(data, 'store_budget')
            data['growth_store_budget_prev'] = do ->
                return data['net_sales']
            data['growth_store_budget'] = do ->
                return data['diff_to_store_budget_percentage']
        # hatch collection
        if _.has(data, 'gross_sales_rof')
            data['growth_gross_sales_rof_prev'] = do ->
                return data['gross_sales']
            data['growth_gross_sales_rof'] = do ->
                return data['diff_to_gross_sales_rof']
        if _.has(data, 'gross_sales_plan')
            data['growth_gross_sales_plan_prev'] = do ->
                return data['gross_sales']
            data['growth_gross_sales_plan'] = do ->
                return data['diff_to_gross_sales_plan']
         # frye
        if _.has(data, 'latest_estimate_demand')
            data['growth_latest_estimate_demand'] = data['diff_to_latest_estimate_demand_percentage']
            data['growth_latest_estimate_demand_prev'] = data['demand_sales']

        if _.has(data, 'latest_estimate_gross_sales')
            data['growth_latest_estimate_gross_sales'] = data['diff_to_latest_estimate_gross_sales_percentage']
            data['growth_latest_estimate_gross_sales_prev'] = data['gross_sales']

        if _.has(data, 'latest_estimate_net_sales')
            data['growth_latest_estimate_net_sales'] = data['diff_to_latest_estimate_net_sales_percentage']
            data['growth_latest_estimate_net_sales_prev'] = data['net_sales']
        return data

    fetchRawData = (query) ->
        $q.all([(new QueryServiceAPI), SalesChartGroupByProperty.fetch(), QueryMetrics.fetch()]).then ([api, groupByProperty, metrics]) ->
            query = Utils.copy(query)
            query.options ?= {}
            query.options.property = [groupByProperty]
            query.options.includeTotals = false
            query.options.metrics = metrics.map (metric) -> metric.field
            return api.query.metricsFunnel(query).then((data) -> data[0]).then (data) ->
                return if not data
                metrics = (try Object.keys(data)) or []
                return modifyBudgetData(data)

    parseNumber = (value) ->
        return null if _.isNil(value)
        value = parseFloat(value)
        return null if Number.isNaN(value)
        return value

    remapData = (charts) -> (data) -> charts.map (chart) ->
        chart: chart
        title: chart.title
        value: do ->
            return null if not data
            value = parseNumber(data[chart.property])
            return value if value is null or not chart.type
            [filter, args...] = chart.type.split(':')
            return $filter(filter)(value, args...)
        growth: do ->
            return null if not data
            return parseNumber(data["growth_#{chart.property}"])
        growth_value_prefix: chart.growth_value_prefix
        inverted: chart.inverted
        growth_value: do ->
            return null if not data
            value = parseNumber(data["growth_#{chart.property}_prev"])
            return value if value is null or not chart.type
            [filter, args...] = chart.type.split(':')
            return $filter(filter)(value, args...)

    fetch: (charts, query) ->
        return fetchRawData(query).then(remapData(charts))
