import 'angular';
import { getOrganization, getUser, AuthUser } from './lib/auth';
import { ConfigAPI } from './lib/config-api';
import { LoadingStatus, Browser } from './lib/services';
import type { IConfigObj } from './lib/types';

const STAGING_HOST = 'dash-staging.42technologies.com';

const isLocalhost = ['localhost', '127.0.0.1'].includes(document.location.hostname);
const isProduction = ['dash.42technologies.com', 'app.42technologies.com'].includes(document.location.hostname);

const ANALYTICS_USER_ID_BLACKLIST = [
    // dev+checklyhq@42technologies.com
    // https://manage.auth0.com/dashboard/us/fortytwo/users/YXV0aDAlN0M2MTcxODkwNWFiOTA3YjAwNjkwNDVjZjI
    'auth0|61718905ab907b0069045cf2',
    // joao+rainforestqa@42technologies.com
    // https://manage.auth0.com/dashboard/us/fortytwo/users/YXV0aDAlN0M2MTM5NGNjODkyNTUwMzAwNmE4MTlhM2U
    'auth0|61394cc8925503006a819a3e',
];

function waitForAnalyticsJS(timeout = 10000) {
    return new Promise<void>(resolve => {
        try {
            LoadingStatus.loading('Setting up instrumentation');
            const timer = setTimeout(() => {
                console.error('[bootstrap] analytics.js timed out.');
                return resolve();
            }, timeout);
            window.analytics.ready(() => {
                console.log('[bootstrap] analytics.js loaded.');
                clearTimeout(timer);
                return resolve();
            });
        } catch (error) {
            console.error('[bootstrap] error in wait for analytics.js:', error);
            return resolve();
        }
    });
}

function identifyUser(user: AuthUser, organizationId: string) {
    if (!window.analytics) return;
    if (!(user?.id && organizationId)) return;
    if (ANALYTICS_USER_ID_BLACKLIST.includes(user.id)) return;
    window.analytics.identify(`${organizationId}/${user.id}`, {
        name: user.name,
        role: user.role,
        organization: organizationId,
        isShopify: user.id.startsWith('shopify|'),
        isLocalhost,
    });
    window.analytics.group(organizationId, { name: organizationId });
}

function getOrganizationConfig(organizationId: string) {
    LoadingStatus.loading('Configuring Dashboard');
    return ConfigAPI.get()
        .then(api => api.organization.get())
        .catch((error: any) => {
            const message = 'Could not fetch organization config.';
            console.error('[bootstrap]', message, error);
            LoadingStatus.error(LoadingStatus.Messages.ConfigurationError(organizationId, 'org-config'));
            analytics.track('error/bootstrap/org-config-fetch-error', { message, error });
            throw error;
        });
}

function bootstrapAngular(orgConfig: IConfigObj) {
    require('./app.coffee');
    angular.module('42.config', []).constant('CONFIG', orgConfig);
    angular.bootstrap('#user-menu', ['42.modules.user-menu', '42.config']);
    angular.bootstrap('#app', ['42.dashboard', '42.config']);
}

const loadOrganizationId = () =>
    getOrganization().then((organizationId: string) => {
        if (!organizationId) throw new Error('missing required organizationId');
        const isSportsdirect = ['sportsdirect'].some(x => organizationId.startsWith(x));
        if (!(isProduction && isSportsdirect)) return organizationId;
        window.location.replace(`https://${STAGING_HOST}`);
        return new Promise<never>(() => {});
    });

// function loadAppConfig() {
//     LoadingStatus.loading('Fetching application config');
//     return AppConfigService.get().catch((error: any) => {
//         LoadingStatus.error(LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-app-config'));
//         throw error;
//     });
// }

function loadAppUser() {
    return getUser().catch((error: any) => {
        LoadingStatus.error(LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-user'));
        throw error;
    });
}

function start() {
    if (Browser.isNotSupported()) {
        LoadingStatus.error(LoadingStatus.Messages.BrowserCompatibilityError('unknown'));
        throw new Error('Browser is not supported');
    }
    return waitForAnalyticsJS().then(() => {
        Promise.all([
            loadAppUser().catch((error: any) => {
                const message = LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-user');
                LoadingStatus.error(message);
                throw error;
            }),
            loadOrganizationId().catch((error: any) => {
                const message = LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-organization');
                LoadingStatus.error(message);
                throw error;
            }),
        ])
            .then(([user, organizationId]) => {
                identifyUser(user, organizationId);
                return getOrganizationConfig(organizationId);
            })
            .then(config => {
                try {
                    return bootstrapAngular(config);
                } catch (error) {
                    LoadingStatus.error(LoadingStatus.Messages.ConfigurationError(config.organization.id, 'bootstrap'));
                    throw error;
                }
            });
    });
}

function patchJQuery($: any) {
    const originalFn = $.fn.data;
    $.fn.data = function () {
        // eslint-disable-next-line prefer-rest-params
        if (arguments[0] === '$binding') return;
        // eslint-disable-next-line prefer-rest-params
        return originalFn.apply(this, arguments);
    };
    return $;
}

function main() {
    if (!window.jQuery) throw new Error('jQuery was not loaded before bootstrap.');
    patchJQuery($);
    return $(start);
}

main();
