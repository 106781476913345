import { compact, keyBy } from 'lodash';
import { titleize } from 'inflected';
import { cloneDeep } from 'lodash';
import { ConfigAPI } from '../lib/config-api';

export interface IHierarchyItem {
    column?: string;
    label?: string;
    name?: string;
    group?: string;
    id?: string;
    plural?: string;
    table?: string;
}

export type IHierarchy = Record<'items' | 'stores' | 'all', IHierarchyItem[]>;
export type IHierarchyDict = Record<string, IHierarchyItem[]>;

export interface IHiearchyDescriptor {
    type_name: string;
    type_id: number;
    name: string;
    table: string;
}

export interface IHierarchyDescriptorsDic {
    budget: IHiearchyDescriptor[];
    calendar: IHiearchyDescriptor[];
    customers: IHiearchyDescriptor[];
    demand: IHiearchyDescriptor[];
    demand_items: IHiearchyDescriptor[];
    employees: IHiearchyDescriptor[];
    inventory: IHiearchyDescriptor[];
    items: IHiearchyDescriptor[];
    load_status: IHiearchyDescriptor[];
    stores: IHiearchyDescriptor[];
    traffic: IHiearchyDescriptor[];
    transaction_items: IHiearchyDescriptor[];
    transactions: IHiearchyDescriptor[];
    transfers: IHiearchyDescriptor[];
}

export interface IDataDescriptorsService {
    fetch(): Promise<IHierarchyDescriptorsDic>;
}

function isHierarchyItemList(input: IHierarchyItem[] | IHierarchyDict): input is IHierarchyItem[] {
    return Array.isArray(input);
}

class HierarchiesMapperHelper {
    static normalizeHierarchyItem = (hierarchyItem: IHierarchyItem) => {
        if (!hierarchyItem.id) {
            if (!(hierarchyItem.table && hierarchyItem.column)) {
                console.error(`Property is misconfigured, must have an 'id', or 'table' and 'column'.`, hierarchyItem);
                throw new Error('Hierarchy attribute is misconfigured');
            }
            hierarchyItem.id = `${hierarchyItem.table}.${hierarchyItem.column}`;
        } else {
            const [table, column] = hierarchyItem.id.split('.');
            hierarchyItem.table ??= table;
            hierarchyItem.column ??= column;
        }
        hierarchyItem.label ??= titleize(hierarchyItem.column);
        hierarchyItem.plural ??= hierarchyItem.label;
        return hierarchyItem;
    };

    static normalizeHierarchy = (group: string, hierarchy: IHierarchyItem[]) => {
        return hierarchy.map(hierarchyItem => {
            hierarchyItem = HierarchiesMapperHelper.normalizeHierarchyItem(hierarchyItem);
            hierarchyItem.group = group;
            return hierarchyItem;
        });
    };

    static convertDescriptorsToHierarchy = (group: string, descriptors: IHiearchyDescriptor[]) => {
        const hierarchy: IHierarchyItem[] = descriptors.map(descriptor => {
            const label = titleize(descriptor.name);
            return {
                label,
                plural: label,
                table: descriptor.table,
                column: descriptor.name,
            };
        });
        try {
            return HierarchiesMapperHelper.normalizeHierarchy(group, hierarchy);
        } catch (error) {
            throw new Error('Could not convert descriptor to hierarchy attribute.');
        }
    };

    static filterHierarchiesEmployeeItems = (hierarchies: IHierarchy): IHierarchy => {
        return (Object.keys(hierarchies) as (keyof IHierarchy)[]).reduce((result, key) => {
            result[key] = hierarchies[key].filter(
                ({ id }) => !(id === undefined || id === 'transactions.employee_id' || /^employees\./.test(id)),
            );
            return result;
        }, {} as typeof hierarchies);
    };

    static buildStoresAndItemsHierarchiesLists = (
        userConfig: any,
        CONFIG: any,
    ): (IHierarchyItem[] | { [key: string]: IHierarchyItem[] })[] => {
        const hierarchiesList = [
            ['store', userConfig?.stores?.hierarchy || CONFIG.stores?.hierarchy2 || CONFIG.stores?.hierarchy],
            ['item', userConfig?.items?.hierarchy || CONFIG.items?.hierarchy2 || CONFIG.items?.hierarchy],
        ];
        return cloneDeep(hierarchiesList).map(([group, hierarchy]) => {
            if (!hierarchy) {
                return [null, null];
            }

            if (Array.isArray(hierarchy)) {
                return HierarchiesMapperHelper.normalizeHierarchy(group, hierarchy);
            }

            return (Object.keys(hierarchy) as (keyof IHierarchy)[]).reduce((result, key) => {
                result[key] = HierarchiesMapperHelper.normalizeHierarchy(group, hierarchy[key]);
                return result;
            }, {} as typeof hierarchy);
        });
    };

    static joinHierarchies(
        stores: IHierarchyItem[],
        items: IHierarchyItem[],
        metricProperties: string[],
        selectedHierarchyId: string,
    ) {
        metricProperties =
            Array.isArray(metricProperties) || !selectedHierarchyId
                ? metricProperties
                : metricProperties[selectedHierarchyId];

        // FIXME: types
        let all: any = stores.concat(items);

        // This is the property id list that is defined in the metrics view section of the config
        if (metricProperties.length !== 0) {
            const index = keyBy(all, elem => elem.id);
            all = compact(
                metricProperties.map(metricProperty => {
                    const property = index[metricProperty];
                    if (!property) console.warn(`Metric property '${metricProperty}' not found in hierarchy.`);
                    return property;
                }),
            );
        }

        return all;
    }

    static mapToHierarchyObject = (
        storesResult: IHierarchyItem[] | IHierarchyDict,
        itemsResult: IHierarchyItem[] | IHierarchyDict,
        selectedHierarchyId: string,
        CONFIG: any,
    ): IHierarchy => {
        const [stores, items]: IHierarchyItem[][] = [storesResult, itemsResult].map(hierarchy => {
            if (isHierarchyItemList(hierarchy)) return hierarchy;
            if (!selectedHierarchyId) {
                console.warn('Trying to fetch hierarchy but hierarchy model is undefined...');
                return [];
            }
            return hierarchy[selectedHierarchyId] as IHierarchyItem[];
        });

        const all = HierarchiesMapperHelper.joinHierarchies(
            stores,
            items,
            CONFIG.views?.metrics?.properties ?? [],
            selectedHierarchyId,
        );

        return { all, stores, items };
    };

    static buildHierarchyFiltersObject = (
        userConfig: any,
        CONFIG: any,
        descriptors: IHierarchyDescriptorsDic,
        selectedHierarchyId: string,
    ) => {
        let [stores, items] = HierarchiesMapperHelper.buildStoresAndItemsHierarchiesLists(userConfig, CONFIG);
        stores ??= HierarchiesMapperHelper.convertDescriptorsToHierarchy('store', descriptors.stores);
        items ??= HierarchiesMapperHelper.convertDescriptorsToHierarchy('item', descriptors.items);
        const hierarchyObject = HierarchiesMapperHelper.mapToHierarchyObject(
            stores,
            items,
            selectedHierarchyId,
            CONFIG,
        );
        return HierarchiesMapperHelper.filterHierarchiesEmployeeItems(cloneDeep(hierarchyObject));
    };
}

export class HierarchyModel {
    available: IHierarchyItem[] = [];
    selected: IHierarchyItem;

    constructor(hierarchies: IHierarchyItem[] | string[]) {
        if (!hierarchies) {
            throw new Error('Missing required `hierarchies` argument.');
        }
        this.available = hierarchies.map((hierarchy: IHierarchyItem | string) => {
            if (typeof hierarchy === 'string') hierarchy = { id: hierarchy, label: titleize(hierarchy) };
            return { ...hierarchy };
        });
        this.selected = this.available[0];
    }

    getSelectedId(): string {
        return this.selected.id as string;
    }

    getAvailableGroups(): IHierarchyItem[] {
        return cloneDeep(this.available);
    }

    setSelectedHierarchy(id: string) {
        if (id === this.selected.id) return;
        this.selected = this.available.find(hierarchy => hierarchy.id === id) as IHierarchyItem;
    }
}

export const HierarchyModelFactory = () => [
    function HierarchyModelFactory() {
        return HierarchyModel;
    },
];

export const HierarchyService = () => [
    '$rootScope',
    '$q',
    'CONFIG',
    'DataDescriptors',
    function HierarchyService(
        $rootScope: angular.IRootScopeService & any,
        $q: angular.IQService,
        CONFIG: any,
        DataDescriptors: IDataDescriptorsService,
    ) {
        const promise = $q.all([ConfigAPI.get().then(api => api.user.get()), DataDescriptors.fetch()]);
        return {
            fetch: () => {
                return promise.then(([userConfig, descriptors]) => {
                    const selectedHierarchyId = $rootScope.hierarchyModel?.selected?.id;
                    return HierarchiesMapperHelper.buildHierarchyFiltersObject(
                        userConfig,
                        CONFIG,
                        descriptors,
                        selectedHierarchyId,
                    );
                });
            },
        };
    },
];

const module = angular.module('42.services.hierarchy', []);
module.factory('HierarchyModel', HierarchyModelFactory());
module.service('Hierarchy', HierarchyService());
