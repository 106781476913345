_ = require('../../lib/lodash-helper.ts')
AuthServiceAPI =  require('../../lib/auth.ts')

module = angular.module '42.modules.views.metrics', [
    '42.modules.libs.utils'
    '42.modules.services'
]

require('./metrics-grid.directive.ts')

module.constant 'METRICS_TABLE_ROW_HEIGHT', 85

module.factory 'MetricsFunnel', (Utils, MetricsFunnelNode) -> class MetricsFunnel

    constructor: (@properties, metrics, selectedMetrics = []) ->
        property = @properties[0]
        @root = new MetricsFunnelNode({@properties, property, level:0})
        @nodes = []
        @nodes.push(@root)
        @metrics =
            available: metrics
            selected:  @_normalizeSelectedMetrics(metrics, selectedMetrics)

    select: (node, value) ->
        properties = _.filter node.properties, (x) -> x.id isnt node.property.id
        property = do ->
            prevIndex = node.properties.map((x)->x.id).indexOf(node.property.id)
            return properties[prevIndex] or properties[0]
        return if not property
        node.value = value
        @nodes = @nodes[0..node.level]
        @nodes.push new MetricsFunnelNode
            properties: properties
            property:   property
            level:      node.level+1
            parent:     node
        return @

    serialize: ->
        properties: @nodes?.map((x) -> x.serialize())
        metrics: @metrics.selected.map((x) -> x.field)

    @deserialize: (properties, metrics, state) ->
        return null if not _.isObject(state)
        metricsFunnel = new MetricsFunnel(properties, metrics, state.metrics)
        metricsFunnel.nodes = do ->
            prevNode = null
            nodes = []
            level = 0
            for x in state.properties
                currNode = MetricsFunnelNode.deserialize(x, properties, prevNode, level)
                nodes.push(currNode) if currNode
                prevNode = currNode
                level++
            return metricsFunnel.nodes if nodes.length is 0
            return nodes
        return metricsFunnel

    _normalizeSelectedMetrics: (availableMetrics, selectedMetrics) ->
        metricsByField = _.keyBy availableMetrics, (x) -> x.field
        selectedMetrics = _.compact selectedMetrics.map (x) -> metricsByField[x.field or x]
        selectedMetricsHeaderGroupOrder = selectedMetrics.reduce ((result, metric) ->
            result[metric.headerGroup] ?= Object.keys(result).length + 1
            return result
        ), {}
        metricHeaderGroupOrder = availableMetrics.reduce ((result, metric) ->
            result[metric.headerGroup] ?= do ->
                selectedOrder = selectedMetricsHeaderGroupOrder[metric.headerGroup]
                return selectedOrder if not _.isUndefined(selectedOrder)
                return (availableMetrics.length + Object.keys(result).length) + 1
            return result
        ), {}
        selectedMetricOrder = selectedMetrics.reduce ((result, metric, index) ->
            result[metric.field] = index
            return result
        ), {}
        return _.sortBy selectedMetrics, [
            ((x) -> metricHeaderGroupOrder[x.headerGroup])
            ((x) -> selectedMetricOrder[x.field])
        ]


module.factory 'MetricsFunnelNode', ($q, Utils, QueryServiceAPI, FileService, QueryMetrics, MetricsFunnelNodeGridViewModelAssociatedColumns, CONFIG) -> class MetricsFunnelNode

    constructor: ({@property, @properties, @level, @parent, @value} = {}) ->
        @level ?= 0

    _fetchMetricsFunnelNode: (query, queryId) ->
        (new QueryServiceAPI).then (api) => api.query[queryId](query).then (data) =>
            return data if query.type is 'xlsx'
            result = data.reduce ((result, row) ->
                collection = if row.property0 is '$total' then 'total' else 'rows'
                result[collection].push(row)
                return result
            ), {rows:[], total:[]}
            return result if not @property.type
            parseFn = switch @property.type
                when 'numeric' then (x) ->
                    parsed = parseInt(x)
                    return x if _.isNaN(parsed)
                    return parsed
                else (x) -> x
            result.rows.forEach (row) ->
                row.property0 = parseFn(row.property0)
            return result


    fetch: (rootQuery, queryId = 'metricsFunnel') ->
        query = Utils.copy @toQuery(rootQuery)

        promise = if queryId == 'metricsFunnel' then QueryMetrics.fetch() else $q.when(undefined)

        promise.then (metrics) =>
            if metrics != undefined
                query.options.metrics = metrics.map (metric) -> metric.field

            return @_fetchMetricsFunnelNode(query, queryId)



    getDrilldownProperties: ->
        current = @parent
        result = []
        while current
            result.push current.property.id
            current = current.parent
        return result

    export: (rootQuery = {}, type = "xlsx") ->
        rootQuery = Utils.copy(rootQuery)
        rootQuery.type = type
        filename = "42-metrics-#{@property.id.replace(/\./g, '-')}.#{type}"
        @fetch(rootQuery, 'frye__productClassification').then FileService.send(filename)

    toQuery: (rootQuery = {}) ->
        property = @property.id
        query = Utils.copy(rootQuery)
        timestamp = query.filters?.transactions?.timestamp
        query.filters = {}
        query.options ?= {}
        query.modifiers ?= {}
        query.options.sort ?= [Utils.copy(@property.sort)] if @property.sort
        query.options.property = property
        query.options.associatedProperties ?= @_getAssociatedProperties()
        query.filters.transactions = {timestamp} if timestamp
        current = @.parent
        return query if not current
        query.filters.items = {}
        while current
            property = current.property.id
            [collection, field] = property.split('.')
            if field is 'compNonComp'
                query.modifiers.compStores = current.value is 'Comp'
            else
                query.filters[collection] ?= {}
                query.filters[collection][field] = current.value
            current = current.parent
        return query

    _getAssociatedProperties: ->
        [descriptors] = new MetricsFunnelNodeGridViewModelAssociatedColumns(@)
        return [] if not descriptors
        return _.uniq descriptors.map (x) -> x.field.replace(/^item_/, 'items.')

    serialize: ->
        property: @property.id
        sort:     @property.sort
        value:    @value

    @deserialize: (data, properties, parent, level) ->
        return null if not data
        property = _.find(properties, (x) -> x.id is data.property)
        return null if not property
        property = Utils.copy(property)
        property.sort = data.sort
        node = new MetricsFunnelNode({property, properties, level, parent, value:data.value})
        node = @hydrateProperties(node, properties)
        return node

    @hydrateProperties: (metricsFunnelNode, properties) ->
        if (metricsFunnelNode.parent == null)
            metricsFunnelNode.properties = properties
            return metricsFunnelNode
        else
            parent = metricsFunnelNode.parent
            properties = _.filter(parent.properties, (val) -> parent.property.id != val.id)
            metricsFunnelNode.properties = properties
            return metricsFunnelNode


module.factory 'GridViewValueFilter', (Utils, FilterCompiler) -> (field) -> class GridViewValueFilter

    init: ({$scope, colDef, filterChangedCallback}) ->
        @scope = $scope
        @field = field
        @scope.onFilterChanged = Utils.function.debounce 400, =>
            value = @scope.filterText
            @doesFilterPass = do ->
                try FilterCompiler.compile colDef.cellFilter, value, ({data}) -> data[field]
                catch e then -> true
            filterChangedCallback()

    getGui: ->
        """
        <div><input type="text" ng-model="filterText" ng-change="onFilterChanged()"></input></div>
        """

    isFilterActive: ->
        value = @scope.filterText
        return not (value in [null, undefined, ''])


module.service 'CellClasses', ->
    percent = (value) ->
        return 'percent-negative' if value < 0
        return 'percent-positive' if value > 0
        return null
    'percent': (cell) ->
        percent(cell.value)
    'percent-inverted': (cell) ->
        percent(cell.value * -1)


module.service 'MetricsGridCellRenderers', ($filter, METRICS_TABLE_ROW_HEIGHT) ->
    MARGIN_HEIGHT = 6

    blank: (row) ->
        return \
        """
        <span class="cell-blank-value">—</span>
        """
    anchor: (row) ->
        return \
        """
        <a href="#{row.value}" target="_blank">#{row.value}</a>
        """
    metric: (row) ->
        return row.value if not row.colDef.cellFilter
        [filter, args...] = row.colDef.cellFilter.split(':')
        return $filter(filter)(row.value, args...)
    image: (row) ->
        return '' if not row.item_image
        return "<img height='#{METRICS_TABLE_ROW_HEIGHT - MARGIN_HEIGHT}' src='#{row.item_image}'>"


module.factory 'MetricsGridDefaultCellRenderer', (MetricsGridCellRenderers) -> (row) ->
    return MetricsGridCellRenderers.blank(row) if _.isNil(row.value)
    return MetricsGridCellRenderers.anchor(row) if row.colDef.field is 'item_image'
    return MetricsGridCellRenderers.metric(row)


# This is a super hack to get the metrics supported by the dataset
module.service 'MetricsKPIsService', ($q, $rootScope, CONFIG, Utils, QueryServiceAPI, QueryMetrics, CellClasses) -> fetch: (query) ->
    query = Utils.copy(query or {})
    query.filters = {transactions:query.filters.transactions}
    query.options = {property:"stores.aggregate"}

    $q.all([
        QueryMetrics.fetch(),
        AuthServiceAPI.getOrganization()
    ]).then ([metrics, org]) ->
        # We don't want to show the percent parent metrics on metrics page because there's only one level.
        if org not in ['allsaints_dev', 'allsaints-new', 'allsaints']
            metrics = metrics.filter (metric) -> not do ->
                /^percentage_parent_/.test(metric.field) or \
                /^growth_percentage_parent_/.test(metric.field)

        metrics = metrics.map (x) ->
            x.width ?= 140
            x._cellClass = x.cellClass
            x.cellClass = CellClasses[x.cellClass] or x.cellClass
            return x

        filterMetric = (kpis) ->
            return kpis.filter (kpi) ->
                return false if not ($rootScope.flags.showBudget or $rootScope.flags.showBudgets) and kpi.indexOf('budget') isnt -1
                # return false if not $rootScope.flags.showSellthrough and kpi.indexOf('sellthru_percentage') is 0
                return true

        getMetricsKPIs = ->
            availableKpis = Utils.copy(metrics)
            enabledKpis = do ->
                userEnabledKpis = $rootScope.accessControl?.kpis
                orgEnabledKpis = CONFIG.views?.metrics?.kpis
                return if not orgEnabledKpis
                return _.compact(_.uniq(_.concat(orgEnabledKpis, userEnabledKpis)))
            return availableKpis if not enabledKpis
            enabledKpis = filterMetric(enabledKpis) if org in ['allsaints_dev', 'allsaints-new', 'allsaints']
            availableKpisIndex = _.keyBy availableKpis, (x) -> x.field
            return _.compact enabledKpis.map (kpi) -> availableKpisIndex[kpi]

        allMetricsFactory = ->
            kpis = getMetricsKPIs()
            kpis.copy = -> allMetricsFactory()
            return kpis

        metricsFactory = (data) ->
            return allMetricsFactory() if not data?[0]
            kpis = getMetricsKPIs()
            supportKpisIndex = Object.keys(data[0]).reduce ((result, field) ->
                result[field] = true
                return result
            ), {}
            result = kpis.reduce ((result, kpi) ->
                result.push(kpi) if supportKpisIndex[kpi.field]
                return result
            ), []
            result.copy = -> metricsFactory(data)
            return result

        return allMetricsFactory() if (not CONFIG.flags?.checkAvailableKpis) or \
            _.startsWith(org, 'sportsdirect') or \
            _.startsWith(org, 'allsaints')    or \
            _.startsWith(org, 'frye')         or \
            _.startsWith(org, 'incipio')      or \
            _.startsWith(org, 'ippolita')     or \
            _.startsWith(org, 'joesjeans')      or \
            (org in ['aquatalia', 'alexisbittar', 'davidyurman'])

        query.options.metrics = metrics
        return (new QueryServiceAPI).then (api) ->
            api.query.metricsFunnel(query).then (data) -> return metricsFactory(data)


module.factory 'MetricsFunnelNodeGridViewModelAssociatedColumns', () -> (node, org) ->
    associatedColumns = []
    width = undefined
    selectedDrilldownProperties = node.getDrilldownProperties()

    switch org
        when 'frye'
            switch node.property.id
                when 'items.class'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                    ]
                when 'items.subclass'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                        {headerName:'Class',      field:'item_class', width:90} if 'items.class' in selectedDrilldownProperties
                    ]
                when 'items.pattern'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                    ]
                when 'items.pattern_sap'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center_sap', width:120}
                        {headerName:'Category',      field:'item_product_category_sap', width:100}
                        {headerName:'Subcategory',   field:'item_product_sub_category_sap', width:115}
                    ]
                when 'items.style'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'Pattern',    field:'item_pattern', width:150, drilldown:true}
                    ]
                when 'items.style_sap'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center_sap', width:120}
                        {headerName:'Category',      field:'item_product_category_sap', width:100}
                        {headerName:'Subcategory',   field:'item_product_sub_category_sap', width:115}
                        {headerName:'Pattern',       field:'item_pattern_sap', width:150, drilldown:true}
                    ]
                when 'items.material'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department',     field:'item_department', width:120}
                        {headerName:'Class',          field:'item_class', width:90}
                        {headerName:'Subclass',       field:'item_subclass', width:100}
                        {headerName:'Pattern',        field:'item_pattern', width:150}
                        {headerName:'Season',         field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',           field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                        {headerName:'Hardmark Price', field:'item_hardmark', width:135, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.material_sap'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center',  field:'item_profit_center_sap', width:120}
                        {headerName:'Category',       field:'item_product_category_sap', width:100}
                        {headerName:'Subcategory',    field:'item_product_sub_category_sap', width:100}
                        {headerName:'Pattern',        field:'item_pattern_sap', width:150}
                        {headerName:'MSRP',           field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                        {headerName:'Hardmark Price', field:'item_hardmark', width:135, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.size'
                    width = 80
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Pattern',  field:'item_pattern', width:250} if 'items.pattern' in selectedDrilldownProperties
                        {headerName:'Class',    field:'item_class',  width:100} if 'items.class' in selectedDrilldownProperties
                        {headerName:'Subclass', field:'item_subclass', width:100} if 'items.subclass' in selectedDrilldownProperties
                    ]
        when 'frye_wholesale'
            switch node.property.id
                when 'items.pattern'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center', width:120}
                        {headerName:'Category',      field:'item_product_category', width:100}
                        {headerName:'SubCategory',   field:'item_product_sub_category', width:120}
                    ]
                when 'items.style'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center', width:120}
                        {headerName:'Category',      field:'item_product_category', width:100}
                        {headerName:'SubCategory',   field:'item_product_sub_category', width:120}
                        {headerName:'Pattern',       field:'item_pattern', width:150}
                        {headerName:'MSRP',          field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.material'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center', width:120}
                        {headerName:'Category',      field:'item_product_category', width:100}
                        {headerName:'SubCategory',   field:'item_product_sub_category', width:120}
                        {headerName:'Pattern',       field:'item_pattern', width:150}
                        {headerName:'MSRP',          field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                   ]
        when 'frye_total'
            switch node.property.id
                when 'items.pattern'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center', width:120}
                        {headerName:'Category',      field:'item_product_category', width:100}
                        {headerName:'SubCategory',   field:'item_product_sub_category', width:120}
                    ]
                when 'items.style'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center', width:120}
                        {headerName:'Category',      field:'item_product_category', width:100}
                        {headerName:'SubCategory',   field:'item_product_sub_category', width:120}
                        {headerName:'Pattern',       field:'item_pattern', width:150}
                        {headerName:'MSRP',          field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.material'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Profit Center', field:'item_profit_center', width:120}
                        {headerName:'Category',      field:'item_product_category', width:100}
                        {headerName:'SubCategory',   field:'item_product_sub_category', width:120}
                        {headerName:'Pattern',       field:'item_pattern', width:150}
                        {headerName:'MSRP',          field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
        when 'joesjeans', 'joesjeans_dtc'
            switch node.property.id
                when 'items.class'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                    ]
                when 'items.subclass'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                        {headerName:'Class', field:'item_class', width:90} if 'items.class' in selectedDrilldownProperties
                    ]
                when 'items.pattern'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class', field:'item_class', width:90}
                        {headerName:'Subclass', field:'item_subclass', width:100}
                        {headerName:'DCS Code', field:'item_dcs_code', width:100, pinned:false}
                    ]
                when 'items.style'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'DCS Code',   field:'item_dcs_code', width:100, pinned:false}
                        {headerName:'Body',       field:'item_pattern', width:150, pinned:false}
                    ]
                when 'items.material'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'DCS Code',   field:'item_dcs_code', width:100, pinned:false}
                        {headerName:'Body',       field:'item_pattern', width:150, pinned:false}
                        {headerName:'Season',     field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',       field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
        when 'aquatalia'
            switch node.property.id
                when 'items.class'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                    ]
                when 'items.subclass'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                        {headerName:'Class',      field:'item_class', width:90} if 'items.class' in selectedDrilldownProperties
                    ]
                when 'items.collection_sap'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                    ]
                when 'items.pattern'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'Collection', field:'item_collection_sap', width:100}
                    ]
                when 'items.style'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'Pattern',    field:'item_pattern', width:150, pinned:false}
                    ]
                when 'items.material'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'Pattern',    field:'item_pattern', width:150, pinned:false}
                        {headerName:'Season',     field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',       field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
        when 'rebeccaminkoff'
            switch node.property.id
                when 'items.name'
                    width = 150
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Division', field:'item_division_name', width:150}
                        {headerName:'Category', field:'item_product_category', width:100}
                        {headerName:'Group',    field:'item_product_group', width:150}
                        {headerName:'Season',   field:'item_season', width:125, pinned:false}
                    ]
                when 'items.sku'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Division', field:'item_division_name', width:150}
                        {headerName:'Category', field:'item_product_category', width:100}
                        {headerName:'Group',    field:'item_product_group', width:150}
                        {headerName:'Name',     field:'item_name', width:150}
                        {headerName:'Season',   field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',     field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.upc'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Division', field:'item_division_name', width:150}
                        {headerName:'Category', field:'item_product_category', width:100}
                        {headerName:'Name',     field:'item_name', width:150}
                        {headerName:'Group',    field:'item_product_group', width:150}
                        {headerName:'SKU',      field:'item_sku', width:150, pinned:false}
                        {headerName:'Season',   field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',     field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.color'
                    width = 80
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Division', field:'item_division_name', width:100} if 'items.division_name' in selectedDrilldownProperties
                        {headerName:'Category', field:'item_category', width:100} if 'items.category' in selectedDrilldownProperties
                        {headerName:'Name',     field:'item_name',  width:150} if 'items.name' in selectedDrilldownProperties
                    ]
        when 'alexisbittar'
            switch node.property.id
                when 'items.style'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Name', field:'item_name', width:200}
                    ]
                when 'items.alu'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Name', field:'item_name', width:200}
                    ]

    return [associatedColumns, width]

module.directive 'metricsFunnelBreadcrumb', ->
    restrict: 'E'
    scope:
        funnel:   '='
        selected: '='
        metrics:  '='
        export:   '='
        onReset:  '&'
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb" ng-if="funnel">
        <section class="funnel-state">
            <header>
                <h1>Selected Filters</h1>
                <button class="reset" ng-if="!isEmpty()" ng-click="onReset()">reset</button>
            </header>
            <p ng-if="isEmpty()" class="help-text">
                <i class="icon-help-circled"></i>
                select a <span class="pellet">group by</span> to see values for that attribute, click on a row's <span class="cell-value no-border"><button>value</button></span> to drill down
            </p>
            <ul class="pellets">
                <li ng-if="node.value" class="funnel-node" ng-repeat="node in funnel.nodes" ng-click="selectNode(node)" ng-class="{selected:selected.node == node}">
                    <span ng-if="!$first" class="separator"><i class="icon-right-thin"></i></span>
                    <div class="pellet">
                        <span class="property">{{ node.property.label }}</span>
                        <span ng-if="node.value" class="value">{{ node.value }}</span>
                    </div>
                </li>
            </ul>
        </section>
        <section class="available-properties">
            <div class="properties-container">
                <header>
                    <h1>Group By</h1>
                </header>
                <!-- Selector: Metrics to Display -->
                <ul class="pellets">
                    <li class="pellet available-property" ng-repeat="property in selected.node.properties" ng-click="selectProperty(property)" ng-class="{selected:selected.property.id == property.id}">
                        <span class="property-label">{{ property.label }}</span>
                    </li>
                </ul>
            </div>
            <div class='buttons-holder'>
                <metrics-selector funnel='funnel'></metrics-selector>
                <button-export class="export panel-button" on-click="export()"></button-export>
            </div>
        </section>
    </article>
    """
    link: (scope) ->
        scope.isEmpty = ->
            nodes = scope.funnel?.nodes
            return true if not nodes
            return nodes.length is 1 and _.isUndefined(nodes[0].value)

        scope.selectNode = (node) ->
            if scope.selected.node.level == node.level
                scope.selected.node = scope.funnel.nodes.find((nodeItem) -> nodeItem.level == (node.level + 1)) || node
            else
                scope.selected.node = node

        scope.selectProperty = (property) ->
            scope.selected.property = property


module.factory 'MetricsViewStorageAPI', ($rootScope, StorageAPI) -> -> new StorageAPI do ->
    hierarchyKey = $rootScope.hierarchyModel?.selected?.id
    prefix = "metrics.views.v1"
    return prefix if not hierarchyKey
    return "#{prefix}.#{hierarchyKey}"


module.factory 'MetricsViewsAPI', ($q, Utils, MetricsViewStorageAPI) -> ->
    (new MetricsViewStorageAPI).then (api) -> api.get().then (initial) ->
        state = initial
        get: ->
            return $q.when Utils.copy(state)
        put: (data) ->
            state = Utils.copy(data)
            api.put(state)
            return $q.when(state)


module.factory 'ColumnViewsAPI', ($q, Utils, StorageAPI) -> ->
    (new StorageAPI 'metrics.column-views').then (api) -> api.get().then (initial) ->
        state = initial
        get: ->
            return $q.when Utils.copy(state)
        put: (data) ->
            state = Utils.copy(data)
            api.put(state)
            return $q.when(state)



module.factory 'TabStateViewModel', (Utils, MetricsViewsAPI, ColumnViewsAPI, MetricsFunnel) -> class TabStateViewModel

    constructor: (@properties, @metrics) ->
        @tabs = []
        @selectedTab = null
        @propertyById = @properties.reduce ((dict, property) ->
            dict[property.id] = property
            return dict
        ), {}

    reset: =>
        @tabs = []
        @addNewTab()

    addNewTab: =>
        return @addTab("New View", @metrics.map((x) -> x.field))

    addTab: (name, selectedMetrics) =>
        tabObj = {name: name, id: Utils.uuid()}
        tabObj.funnel = new MetricsFunnel(@properties, @metrics, selectedMetrics)
        tabObj.selected =
            node:     tabObj.funnel.nodes[tabObj.funnel.nodes.length-1]
            property: null
        tabObj.reset = @getTabResetter(tabObj)
        @tabs.push(tabObj)
        @selectedTab = @tabs[@tabs.length-1]

    duplicated: =>
        funnelState = @selectedTab.funnel.serialize()
        tabObj = Utils.copy(@selectedTab)
        Object.keys(tabObj).filter((k) -> k.indexOf('$') is 0).forEach (k) -> delete tabObj[k]
        tabObj.id = Utils.uuid()
        tabObj.name = do =>
            regexp = /\(copy( \d+)?\)$/
            name = @selectedTab.name
            matches = name.match(regexp)
            index = do ->
                return 0 if not matches
                return 1 if matches and not matches[1]
                return parseInt(matches[1])
            name = name.replace(regexp, "") if matches
            return "#{name} (copy)" if index is 0
            return "#{name} (copy #{index+1})"
        tabObj.funnel = MetricsFunnel.deserialize(@properties, @metrics, funnelState)
        tabObj.selected =
            node:     tabObj.funnel.nodes[tabObj.funnel.nodes.length-1]
            property: null
        tabObj.reset = @getTabResetter(tabObj)
        index  = @tabs.indexOf(@selectedTab)
        index ?= @tabs.length - 1
        @tabs = Utils.insertAt(@tabs, (index+1), tabObj)
        @selectedTab = tabObj

    getTabResetter: (tabObj) ->
        return (->
            @tabObj.selected = {node:null, property:null}
            @tabObj.funnel = new MetricsFunnel(@properties, @metrics, @tabObj.funnel.metrics.selected)
        ).bind({tabObj, @properties, @metrics})

    removeTab: (id) =>
        return if not id
        index = _.findIndex @tabs, (x) -> x.id is id
        @tabs = @tabs.filter((x) -> x.id isnt id)
        @selectedTab = @tabs[Math.min(@tabs.length-1, index)]

    reorderTabs: (oldIndex, newIndex) =>
        @tabs = Utils.move(@tabs, oldIndex, newIndex)
        @selectedTab = @tabs[newIndex]
        @saveDrillDownState(@selectedTab.selected)

    saveDrillDownState: _.debounce((selected) ->
        return if not selected
        tabData =
            available: @tabs.map (x) ->
                {properties, metrics} = x.funnel.serialize()
                return {id:x.id, name:x.name, properties, metrics}
            selected: @selectedTab.id
        (new MetricsViewsAPI).then (api) -> api.put(tabData)
    , 1000)

    loadDrillDownState: ->

        loadTabs = =>
            (new MetricsViewsAPI).then (api) => api.get().then (drillDownResult) =>
                return false if (drillDownResult or []).length is 0
                tabs = drillDownResult.available
                selectedTabId = drillDownResult.selected
                return false if (tabs or []).length is 0
                @tabs = tabs.map (x) =>
                    funnel = MetricsFunnel.deserialize(@properties, @metrics, x)
                    id: x.id
                    name: x.name
                    funnel: funnel
                    selected: do ->
                        node = funnel.nodes[funnel.nodes.length-1]
                        node: node
                        property: node.property
                @tabs.forEach((tab) => tab.reset = @getTabResetter(tab))
                @selectedTab = _.find(@tabs, (x) -> x.id is selectedTabId) or @tabs[0]
                return true

        loadOldColumnTabs = =>
            (new ColumnViewsAPI).then (api) => api.get().then (data) =>
                return false if (data or []).length is 0
                data.forEach (state) => @addTab(state.label, state.columns)
                return true

        loadTabs().then (hasNewTabs) =>
            return if hasNewTabs
            loadOldColumnTabs().then (hasOldTabs) => return @reset() if not hasOldTabs
        .then =>
            @selectedTab ?= @tabs[0]


module.directive 'metricsView', (MetricsFunnel, MetricsFunnelNode, TabStateViewModel, Utils) ->
    restrict: 'E'
    scope:
        model: '='
        organization: '='
    replace: true
    template: \
    """
    <section class="view view-metrics">
        <div class='loadable' ng-class='{loading:!model}'></div>
        <tabs-with-menu
            tabs="tabStateViewModel.tabs"
            added="tabStateViewModel.addNewTab"
            removed="tabStateViewModel.removeTab"
            selected="tabStateViewModel.selectedTab"
            dragged="tabStateViewModel.reorderTabs"
            duplicated="tabStateViewModel.duplicated"
            >
        </tabs-with-menu>
        <metrics-funnel-breadcrumb
            export="export"
            funnel="tabStateViewModel.selectedTab.funnel"
            selected="tabStateViewModel.selectedTab.selected"
            on-reset="tabStateViewModel.selectedTab.reset()">
        </metrics-funnel-breadcrumb>
        <metrics-funnel-node-view
            organization="model.organizationId"
            export-setter="exportSetter"
            funnel="tabStateViewModel.selectedTab.funnel"
            selected="tabStateViewModel.selectedTab.selected">
        </metrics-funnel-node-view>
    </section>
    """
    link: (scope) ->

        scope.exportSetter = (exportFunc) ->
            scope.export = exportFunc

        scope.$watch 'model', (model) ->
            return if not model
            scope.tabStateViewModel = new TabStateViewModel(scope.model.properties, scope.model.metrics)
            scope.tabStateViewModel.loadDrillDownState()

        scope.$watch 'tabStateViewModel.selectedTab.funnel.nodes', ((nodes) ->
            return if not nodes
            scope.tabStateViewModel.selectedTab.selected.node = nodes[nodes.length-1]
        ), true

        save = ->
            return if not scope.tabStateViewModel?.selectedTab?.selected
            scope.tabStateViewModel.saveDrillDownState(scope.tabStateViewModel.selectedTab.selected)

        scope.$watch 'tabStateViewModel.selectedTab', save, true



module.factory 'MetricsSelectorPanelViewModel', ->

    class MetricsSelectorPanelFilterViewModel
        constructor: (value = "") ->
            @value = value
        clear: ->
            @value = ""

    class MetricsSelectorPanelViewModel
        constructor: ->
            @isActive = false
            @filter = new MetricsSelectorPanelFilterViewModel()
        show: ->
            @isActive = true
        hide: ->
            @isActive = false
        toggle: ->
            @isActive = !@isActive

    return MetricsSelectorPanelViewModel


module.directive 'metricSelectorFilter', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="metric-selector-filter">
        <i class="icon-search"></i>
        <input ng-model="model.value" placeholder="Filter Metrics"></input>
        <button ng-click="model.clear()" ng-class="{active:model.value}">clear</button>
    </article>
    """

module.directive 'metricsSelector', (Utils, OutsideElementClick, MetricsSelectorPanelViewModel) ->
    restrict: 'E'
    scope:
        funnel: '='
    replace: true
    template: \
    """
    <div class='metrics-selector-container'>
        <button class="metric-edit-button panel-button" ng-click="panel.toggle()" ng-class="{active:panel.isActive}">
            <i class="icon-pencil"></i>
            <span class="edit-metrics-label">Edit Metrics</span>
        </button>
        <article class="metric-selector" ng-show='panel.isActive' ng-if="funnel">
            <header class="metric-filter-header">
                <metric-selector-filter model="panel.filter"></metric>
            </header>
            <div class='metric-selector-tree'></div>
        </article>
    </div>
    """
    link: (scope, element) ->
        $element = $(element)
        $selectorTree = $element.find('.metric-selector-tree')

        selectorTreeEvents = [
            'loaded.jstree'
            'check_node.jstree'
            'uncheck_node.jstree'
            'move_node.jstree'
        ]

        scope.panel = new MetricsSelectorPanelViewModel()

        getSelected = ->
            getBottomCheckedOrdered = (arr, cb) -> _.flatMap arr, (x) ->
                return getBottomCheckedOrdered(x.children, cb) if x.children.length > 0
                return if cb(x) then x.data.metric else []
            return getBottomCheckedOrdered($selectorTree.jstree('get_json'), (x) -> x.state.checked)

        createConfig = (metricsTreeData) ->
            core:
                data: metricsTreeData
                themes:
                    icons: false
                check_callback: (operation, node, node_parent, node_position, more) ->
                    return false if operation in ['create_node', 'editMode_node', 'delete_node', 'copy_node']
                    return node.id in node_parent.children # only allow rearranging children of nodes, not changing structure.
            plugins: ['checkbox', 'dnd', 'search']
            checkbox:
                tie_selection: false
            dnd:
                copy: false
                drag_selection: false
            search:
                fuzzy: false
                show_only_matches: true
                show_only_matches_children: true

        createNewChild = (metric, selectedMetricFields) ->
            text: metric.headerName
            id: metric.field
            order: do ->
                indexOfChild = selectedMetricFields.indexOf(metric.field)
                return indexOfChild if indexOfChild > -1
                return selectedMetricFields.length + 1
            data:
                metric: metric
            state:
                checked:(metric.field in selectedMetricFields)

        createData = (selected, available) ->
            selectedMetricFields = selected.map (x) -> x.field
            header = {}
            headerList = []
            metricTree = available.reduce ((prev, curr, index) ->
                if curr.headerGroup isnt header.text
                    if curr.headerGroup and headerList.indexOf(curr.headerGroup) > -1
                        metricItem = prev.find((item) -> item.id == curr.headerGroup)
                        metricItem.children.push(createNewChild(curr, selectedMetricFields))
                        metricItem.children = _.sortBy metricItem.children, (x) -> x.order
                        return prev

                    header =
                        children: []
                        text: curr.headerGroup
                        id:   curr.headerGroup
                    headerList.push(curr.headerGroup)
                    prev.push(header)
                if curr.headerName is ''
                    header.id = curr.field
                    header.state = checked:(curr.field in selectedMetricFields)
                    header.data = metric: curr
                else
                    header.children.push(createNewChild(curr, selectedMetricFields))
                    header.children = _.sortBy header.children, (x) -> x.order
                return prev
            ), []
            getFirstChild = (a) -> _.minBy(a.children, (child) -> child.order)
            metricTree = _.sortBy metricTree, (x) -> getFirstChild(x)?.order
            text: 'All Metrics'
            id:   '__all_metrics'
            state: {opened: true}
            children: metricTree

        createTreeConfig = ->
            {selected, available} = Utils.copy(scope.funnel?.metrics or {})
            data = createData(selected, available)
            return createConfig(data)

        updateModel = () ->
            selected = getSelected()
            scope.funnel.metrics.selected = selected

        destroyTree = ->
            $selectorTree = $element.find('.metric-selector-tree')
            selectorTreeEvents.forEach (x) -> $selectorTree.off(x, updateModel)
            $selectorTree.jstree('destroy')

        createTree = ->
            $selectorTree = $element.find('.metric-selector-tree')
            $selectorTree.jstree(createTreeConfig())
            selectorTreeEvents.forEach (x) -> $selectorTree.on(x, updateModel)

        cleanFn = null
        reload = ->
            destroyTree()
            createTree()
            cleanFn?()
            cleanFn = \
            OutsideElementClick scope, $element.find('.metric-selector, .metric-edit-button'), ->
                scope.panel.hide()

        scope.$watch 'panel.filter.value', (value) ->
            $selectorTree = $element.find('.metric-selector-tree')
            $selectorTree.jstree('search', value)

        initialized = true

        scope.$watch 'funnel', ((funnel, prev) ->
            return if !funnel

            if (initialized)
                reload()
                initialized = false

            isNewMetrics = !_.isEqual(funnel.metrics.selected, prev.metrics.selected)
            isNewSelectedMetrics = !_.isEqual(funnel.metrics.selected, getSelected())

            reload() if (isNewMetrics and isNewSelectedMetrics)
        ), true

        scope.$on '$destroy', ->
            destroyTree()
