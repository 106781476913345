_      = require('../lib/lodash-helper.ts')
moment = require 'moment'

{ LoadingStatus } = require('../lib/services.ts')
AuthServiceAPI    = require('../lib/auth.ts')

{ Browser } = require('../lib/browser.helper.ts')
PageFlags = require('../lib/config-page-flags.ts')
{ SmartGroupsViewModel } = require('../modules/smart-groups/smart-group-panel.directive.ts')

require '../services'
require '../modules/modules.coffee'
module = angular.module '42.controllers.main', [
    '42.modules',
    '42.services',
]
require('./main/main-navbar.coffee')
require('./main/status-message-bar-module.coffee')

DataLoadingInProgressError = do ->
    subclass = ->
        @name = 'DataLoadingInProgressError'
        @message = 'Data loading is in progress'
        @stack = (new Error).stack
    subclass:: = new Error
    return subclass

module.controller 'MainController',

($rootScope, $scope, $q, $timeout, $location, $window, SmartGroupsAPI, CONFIG, Utils, StatusMonitor, StatusMessageModel, TimeRange, UserCurrencyModel, HierarchyModel, SmartGroups, SmartGroupFilters, AccessControl, Flags) ->
    $scope.organization = CONFIG.organization
    $scope.navbarModel = {expand: false}
    $rootScope.datepicker = {models:null, actions:null, bounds:null}

    $rootScope.initialized = false
    $rootScope.statusMessageModel = new StatusMessageModel()
    $rootScope.hideSidebar = false
    $rootScope.sidebarAnimating = false

    if CONFIG.hierarchies
        $rootScope.hierarchyModel = new HierarchyModel(CONFIG.hierarchies)

    $scope.TimeRange = TimeRange

    $rootScope.StatusMonitor = StatusMonitor
    $rootScope.Browser = Browser

    LoadingStatus.loading "Checking Data Status"

    $scope.isLoading = false

    $q.all([
        AuthServiceAPI.getUser(),
        AuthServiceAPI.getOrganization(),
        StatusMonitor.updateStatus()
    ])
    .catch (error) ->
        console.error "Could not fetch dashboard status:"
        console.error error
        return null
    .then ([userInfo, organization, status]) ->
        console.log "Database Status:", status
        $rootScope.organizationId = organization

        if status?.isLoading
            $scope.isLoading = true
            LoadingStatus.loading(LoadingStatus.Messages.LoadingDataFromAppInit())
            StatusMonitor.start()
            $rootScope.$watch 'StatusMonitor.status.isLoading', (loading, oldIsLoading) ->
                return if _.isNil(loading)
                oldIsLoading   = false if _.isNil(oldIsLoading)
                loadingIsDone  = oldIsLoading is true  and loading is false
                $window.location.reload() if loadingIsDone
            analytics.track "loading-start", {status:StatusMonitor.status}
            console.warn("Data is being loaded...")
            return $q.reject(new DataLoadingInProgressError())

        LoadingStatus.loading "Initializing Dashboard"

        smartGroupsApiPromise = new SmartGroupsAPI()

        return $q.all([
            smartGroupsApiPromise
            smartGroupsApiPromise.then (api) -> api.get()
            UserCurrencyModel.fetch()
            TimeRange.fetch()
            AccessControl
            Flags,
            PageFlags.fetch(),
        ]).catch (error) ->
            analytics.track "error/app/initialization-failed", {error}
            LoadingStatus.error LoadingStatus.Messages.InitializationError(organization)
            console.error "Initialization error:", error
            throw error

    .then ([smartGroupsAPI, groups, currencyModel, timespan, accessControl, flags, pageFlags]) ->
        $scope.pages = pageFlags

        if accessControl?.status is 'suspended'
            LoadingStatus.error LoadingStatus.Messages.SuspendedMessage($rootScope.organizationId)
            return

        LoadingStatus.loading "Applying finishing touches"

        console.log "Flags:"
        console.log JSON.stringify(flags, null, 2)

        $rootScope.accessControl = accessControl
        $rootScope.flags = flags or {}

        $rootScope.currencyModel = currencyModel
        $rootScope.$watch 'currencyModel.selected.id', (currency) ->
            return if not currency
            try $rootScope.currencyModel.save()
            return if not $rootScope.query
            $rootScope.query.modifiers ?= {}
            $rootScope.query.modifiers.currency = currency

        createHandlers = (hierarchy) ->
            create: (view) ->
                smartGroupsAPI.create view.getModel(), hierarchy
                console.warn('SmartGroup Created', view.getModel())
            update: (view) ->
                smartGroupsAPI.update view.getModel(), hierarchy
                console.warn('SmartGroup Updated', view.getModel())
            reorder: (oldIndex, newIndex) ->
                smartGroupsAPI.reorder oldIndex, newIndex, hierarchy
            delete: (view) ->
                smartGroupsAPI.delete view.getModel(), hierarchy

        updateSmartGroupService = (groups, hierarchy) ->
            return new SmartGroups(groups, createHandlers(hierarchy), hierarchy)

        if $rootScope.hierarchyModel
            if _.isArray(groups)
                newGroups = {}
                selectedId = $rootScope.hierarchyModel.getSelectedId()
                newGroups[selectedId] = groups
                groups = newGroups
            smartGroupControllers = $rootScope.hierarchyModel.getAvailableGroups().reduce ((result, hierarchy) ->
                key = hierarchy.id
                groups[key] ?= []
                result[key] = updateSmartGroupService(groups[key], key)
                return result
            ), {}

            $rootScope.$watch 'hierarchyModel.selected', (selected) ->
                return if not selected
                $rootScope.smartGroupsService = smartGroupControllers[selected.id]
                $rootScope.smartGroupsService.groups[0].select()
                $rootScope.smartGroupsPanelViewModel = new SmartGroupsViewModel($rootScope.smartGroupsService)

                # Hierarchy switch smart groups change animation. It would be nice if this
                # dom stuff was in a directive, but I don't know how to refactor this yet.
                oldSmartGroupsPanel = do ->
                    $el = $('.smart-groups-panel')
                    $el: $($el[0]?.outerHTML)
                    offset: $el.offset()
                    width:  $el.width()
                    height: $el.height()
                return if not oldSmartGroupsPanel.$el
                # This is some trick to prevent the animation from showing when the dashboard first loads.
                isBeforeGroupsInitialization = oldSmartGroupsPanel.$el.find(".smart-groups li").length is 0
                return if isBeforeGroupsInitialization
                $('#sidebar main').append(oldSmartGroupsPanel.$el)
                oldSmartGroupsPanel.$el.addClass('.smart-groups-panel-old')
                oldSmartGroupsPanel.$el.css
                    'z-index': 9
                    'user-select': 'none'
                    'border-left': '1px solid #e9eaed'
                    position: 'fixed'
                    top:    oldSmartGroupsPanel.offset.top
                    left:   oldSmartGroupsPanel.offset.left-1
                    height: oldSmartGroupsPanel.height
                    width:  oldSmartGroupsPanel.width+2
                    opacity: 1
                $timeout (-> oldSmartGroupsPanel.$el.css
                    transform: 'translate3d(240px, 0px, 0px)'
                    opacity: 0
                ), 0
                $timeout (->
                    oldSmartGroupsPanel.$el.remove()
                    delete oldSmartGroupsPanel.$el
                ), 1000

        else
            $rootScope.smartGroupsService = updateSmartGroupService(groups)
            $rootScope.smartGroupsService.groups[0].select()
            $rootScope.smartGroupsPanelViewModel = new SmartGroupsViewModel($rootScope.smartGroupsService)

        StatusMonitor.start()

        onTransactionTimestampChanged = ->
            return if not $rootScope.query
            timestamp = $rootScope.query.filters?.transactions?.timestamp
            return if not timestamp
            dayDifference = moment.utc(timestamp.$lt).diff(moment.utc(timestamp.$gte), 'days')
            return if dayDifference >= 7
            interval = Math.round(Math.random() * 1000 * 5)
            $timeout (->
                console.log "Refreshing dashboard due to status update..."
                onStatusMessageChanged()
                $rootScope.$broadcast('query.refresh')
            ), interval

        onStatusMessageChanged = -> $rootScope.statusMessageModel.update(StatusMonitor.status)

        $rootScope.$watch 'StatusMonitor.status.messageId', onStatusMessageChanged
        $rootScope.$watch 'StatusMonitor.status.latestTransactionTimestamp', onTransactionTimestampChanged

        $rootScope.$watch 'StatusMonitor.status.isLoading', (loading, oldIsLoading) ->
            return if _.isUndefined(loading)
            oldIsLoading   = false if _.isUndefined(oldIsLoading)
            loadingIsDone  = oldIsLoading is true  and loading is false
            loadingStarted = oldIsLoading is false and loading is true
            if loadingStarted
                $rootScope.initialized = false
                analytics.track "loading-start", {status:StatusMonitor.status}
                LoadingStatus.loading(LoadingStatus.Messages.LoadingDataFromAppStarted())
                return
            if loadingIsDone
                $window.location.reload()

        $rootScope.$watch 'Browser.Visibility.isVisible', (isVisible) ->
            StatusMonitor.stop() if not isVisible
            StatusMonitor.start() if isVisible

        previousSmartGroupsService = null
        $rootScope.$watch 'smartGroupsService.selected', (selected) ->
            isGroupSwitch = do ->
                previousSmartGroupsService and \
                $rootScope.smartGroupsService is previousSmartGroupsService
            previousSmartGroupsService = $rootScope.smartGroupsService
            $rootScope.smartGroupsPanelViewModel = new SmartGroupsViewModel($rootScope.smartGroupsService)

            # hack: Leaves time for the group switch animation to complete. Otherwise we get jank.
            $timeout (->
                model = $rootScope.smartGroupsService.selected?.getModel()

                $rootScope.selectedGroupIdHash = model.id
                $rootScope.query = model.query
                $rootScope.query.modifiers ?= {}
                $rootScope.query.modifiers.currency = $rootScope.currencyModel?.selected?.id
                $rootScope.query.filters    ?= {}
                $rootScope.query.comparison ?= {}
                $rootScope.query.options    ?= {}

                if $rootScope.queryState.previous?.filters?.transactions?.timestamp
                    $rootScope.query.filters.transactions ?= {}
                    $rootScope.query.filters.transactions.timestamp = Utils.copy($rootScope.queryState.previous.filters.transactions.timestamp)

                if $rootScope.queryState.previous?.comparison
                    $rootScope.query.comparison = Utils.copy($rootScope.queryState.previous.comparison)
            ), (if isGroupSwitch then 300 else 0)

        $rootScope.queryState =
            previous:         null
            changeCounter:    0
            broadcastCounter: 0

        broadcastChange = (current, previous) ->
            $rootScope.queryState.broadcastCounter += 1
            $rootScope.$broadcast('query.refresh')

        $rootScope.getQueryHash = ->
            query = $rootScope.query
            return if not query
            return $rootScope.selectedGroupIdHash + Utils.object.hash(query)

        $rootScope.$watch 'getQueryHash()', (queryHash) ->

            console.group("Query changed")
            console.log "previous:"
            console.log JSON.stringify($rootScope.queryState.previous, null, 2)
            console.log "current:"
            console.log JSON.stringify($rootScope.query, null, 2)
            console.groupEnd()

            $rootScope.queryState.changeCounter += 1
            $scope.queryState.previous = Utils.copy($rootScope.query)
            $scope.query               = $rootScope.query

            broadcastChange($rootScope.query, $rootScope.queryState.previous)

            # This is a little bit of a hack...
            #
            # Basically, we wait until the root query object is populated with
            # the currency and timestamp stuff.
            #
            # These things will be initialized pretty quickly, so what happens is
            # that the widgets will do a query when the query object is first initialized
            # to {}, then another one when the currency stuff is added, and finally another
            # when the timestamp stuff is added. So 3 queries that quickly overwrite eachother.
            #
            # This is not good when querying the query service with a cold cache, when it has
            # to crunch a lot of data. Perfomance suffers.
            #
            # So, we just wait until this currency and timestamp stuff is created, and then we
            # set the initialized flag to true.
            initialized =
                currency:  $rootScope.query?.modifiers?.currency
                timestamp: $rootScope.query?.filters?.transactions?.timestamp

            $rootScope.initialized = !!(initialized.currency and initialized.timestamp)
            $rootScope.smartGroupsPanelViewModel = new SmartGroupsViewModel($rootScope.smartGroupsService)
            LoadingStatus.done() if $rootScope.initialized

    .catch (error) ->
        return if error instanceof DataLoadingInProgressError
        throw error


module.factory 'AccessControl', (StorageAPI) ->

    fetch = ->
        return (new StorageAPI 'accessControl').then((api) -> api.get())

    normalizeStatus = (status) ->
        status = status or 'active'
        status = status.toString().toLowerCase()
        return status

    return fetch().then (accessControl) ->
        accessControl = accessControl or {}
        accessControl.status = normalizeStatus(accessControl.status)
        accessControl.filters ?= {}
        return accessControl


module.factory 'Flags', (StorageAPI) ->
    (new StorageAPI 'flags').then((api) -> api.get())



module.service 'StatusMonitor', ($timeout, Utils, QueryServiceAPI) ->

    class StatusMonitor
        constructor: (interval) ->
            @interval = interval ? 10000
            @state  = {cancelTimerFn:null, promise:null}
            @status = null
            @updatedAt = null
            @started = false
        start: ->
            return if @started
            console.debug "[StatusMonitor] Starting..."
            @started = true
            @_poll()
        stop: ->
            console.debug "[StatusMonitor] Stopping..."
            $timeout.cancel(@state.cancelTimerFn)
            @state.cancelTimerFn = null
            @started = false
        getStatus: ->
            return null if not @status
            return Utils.copy(@status)
        updateStatus: ->
            @state.promise ?= new QueryServiceAPI()
            timeout = Math.ceil(@interval * 0.95)
            @state.promise.then (api) ->
                api.getStatus({}, {timeout})
            .then (status) =>
                @status = status
                return @status
            .catch (error) =>
                console.error "[StatusMonitor] Could not get data status from query service:"
                console.error error
                return @status
        _update: ->
            @updateStatus().catch(->).then =>
                @updatedAt = moment.utc()
                return
        _poll: (fn) ->
            return if not @started
            timeout = @_getNextTimeout()
            hasBeenResumed = timeout isnt @interval and timeout isnt 0
            $timeout.cancel(@state.cancelTimerFn)
            @state.cancelTimerFn = $timeout (=>
                @_update().then => @_poll()
                return
            ), timeout
            return
        _getNextTimeout: ->
            timeSinceLastUpdate = do =>
                return Infinity if @updatedAt is null
                return (moment.utc().valueOf() - @updatedAt.valueOf())
            return Math.max(0, @interval - timeSinceLastUpdate)

    return new StatusMonitor()


module.service 'TimeRange', (QueryServiceAPI, CONFIG) ->
    fetch: (query) -> (new QueryServiceAPI).then (api) ->
        api.query.getFullTimeSpan(query or {}).then ([result]) ->
            {min_timestamp:min, max_timestamp:max} = result
            max = do ->
                return moment().subtract(1, 'days').format('YYYY-MM-DD') if CONFIG.defaults.maxTimestamp is 'yesterday'
                return max
            [start, end] = [min, max].map (x) -> moment(x).format('YYYY-MM-DD')
            return {start, end}

module.service "ItemConfig", ($q, CONFIG, DataDescriptors, Utils) ->
    config = Utils.copy(CONFIG.items or {})
    config.properties ?= {}
    config.properties.category ?= 'category'
    config.associations ?= []
    promise = (do ->
        return $q.when(config) if config.hierarchy
        DataDescriptors.fetch().then (descriptors) ->
            config.hierarchy = (descriptors.items.map (x) -> x.name).sort()
            return config
    )
    fetch: -> promise.then (x) -> Utils.copy(x)


module.service "StoreConfig", ($q, CONFIG, DataDescriptors, Utils) ->
    config = Utils.copy(CONFIG.stores or {})
    promise = (do ->
        return $q.when(config) if config.hierarchy
        DataDescriptors.fetch().then (descriptors) ->
            config.hierarchy = (descriptors.stores.map (x) -> x.name).sort()
            return config
    )
    fetch: -> promise.then (x) -> Utils.copy(x)


module.service "TimeSpan", (QueryServiceAPI) ->

    fetch: (query = {}) -> (new QueryServiceAPI).then (api) ->
        api.query.getTimeSpan(query).then (data) ->
            {min_timestamp:min, max_timestamp:max} = data[0]
            [start, end] = [min, max].map (x) -> moment.utc(x).format('YYYY-MM-DD')
            return {start, end}


module.service 'QueryMetrics', ($rootScope, $q, CONFIG, QueryServiceAPI, Utils) ->
    TEMPLATED_KEYS = ['headerGroup', 'headerName']
    cache = null

    applyCurrencyToMetrics = (metrics, currency) ->
        currency = do ->
            currency ?= $rootScope.currencyModel?.selected
            return currency if not _.isString(currency)
            currency  = currency.toLowerCase()
            available = $rootScope.currencyModel?.available or []
            return _.find available, (x) -> x.id is currency
        symbol = currency?.symbol or "$"
        metrics.forEach (metric) -> TEMPLATED_KEYS.forEach (key) ->
            metric[key] = (metric[key] or "").replace('[currency]', symbol)
        return metrics


    applyCategoryOverridesToMetrics = (metrics, categoryOverrides) ->
        metrics = Utils.copy(metrics)
        metricsByCategory = _.groupBy metrics, (x) -> x.category

        overrides = do ->
            result = Utils.copy(categoryOverrides or {})
            return {} if not _.isObject(result)

            result = Object.keys(result).map (category) ->
                categoryOverride = result[category]
                categoryMetrics = metricsByCategory[category] or []
                if categoryMetrics.length is 0
                    console.warn("Can't override metric category, the category does not exist:", category)
                return categoryMetrics.map (x) ->
                    override = Utils.copy(categoryOverride)
                    override.field = x.field
                    return override

            result = _.flatten(result)
            result = _.keyBy(result, (x) -> x.field)

            return Object.keys(result).reduce((obj, x) ->
                delete result[x].field
                obj[x] = result[x]
                return obj
            , {})

        return applyOverridesToMetrics(metrics, overrides)


    applyOverridesToMetrics = (metrics, overrides) ->
        metrics = Utils.copy(metrics)
        metricsByField = _.keyBy metrics, (x) -> x.field

        overrides = do ->
            result = Utils.copy(overrides or {})
            return {} if not _.isObject(result)
            return result

        for field in Object.keys(overrides)
            override = overrides[field]
            if not _.isObject(override)
                console.warn "Metric override `#{field}` is not an object."
                console.warn override
                continue
            metric = metricsByField[field]
            delete override.field
            if not metric
                console.warn "Metric override `#{field}` has no matching metric."
                console.warn override
                continue

            templateOptions = {interpolate:/{{([\s\S]+?)}}/g}
            for key in Object.keys(override)
                override[key] = _.template(override[key], templateOptions)(metric)

            _.extend(metric, override)

        return metrics

    filterMetricsByWhitelist = (metrics) ->
        metricsByField = _.keyBy metrics, (x) -> x.field
        whitelist = do ->
            userEnabledKpis = $rootScope.accessControl?.kpis
            orgEnabledKpis = CONFIG.views?.metrics?.kpis
            return null if not orgEnabledKpis
            return _.compact(_.uniq(_.concat(orgEnabledKpis, userEnabledKpis)))
        return metrics if not whitelist
        return whitelist.map((x) -> metricsByField[x]).filter((x) -> x)

    fetchQueryServiceMetrics = do ->
        cache = null
        return ->
            cache ?= (new QueryServiceAPI).then((api) -> api.getMetrics())
            return cache.then (x) -> Utils.copy(x)


    fetchConfiguredMetricDefinitions = -> $q.when do ->
        definitions = Utils.copy(CONFIG.kpis?.definitions or {})
        return Object.keys(definitions).map (id) ->
            definition = definitions[id]
            definition.field = id
            return definition

    fetchCustomFoundationMetrics = -> $q.when do ->
        foundations = Utils.copy(CONFIG.kpis?.foundations or {})
        return Object.keys(foundations).reduce ((result, foundation) ->
            foundation = foundations[foundation]
            Object.keys(foundation).map (id) ->
                metric = foundation[id]
                metric.field = id
                result[id] = metric
            return result
        ), {}

    fetchCustomFilteredMetricsAsDefinitions = (metrics, definitions) -> $q.when do ->
        metricsByField = _.keyBy(metrics, 'field')
        definitionsByField = _.keyBy(definitions, 'field')
        metricFilters = Utils.copy(CONFIG.kpis?.filters or {})
        return Object.keys(metricFilters).reduce(((result, id) ->
            {label, metrics} = metricFilters[id]
            return result.concat _.compact metrics.map (metricId) ->
                field = "#{id}_#{metricId}"
                if definitionsByField[field]
                    console.warn('Filtered metric', field, 'already defined.')
                    return
                metric = metricsByField[metricId]
                if not metric
                    console.warn('Filtered metric', field, 'is missing definition for', metricId)
                    return
                definition = Utils.copy(metric)
                definition.headerGroup = "#{label} #{definition.headerGroup}"
                definition.field = field
                definition.query = field
                return definition
        ), [])

    fetchBaseMetrics = ->
        $q.all([
            fetchQueryServiceMetrics()
        ,   fetchCustomFoundationMetrics()
        ]).then ([queryServiceMetrics, customFoundationMetrics]) ->
            queryServiceMetrics     = _.keyBy(queryServiceMetrics, 'field')
            customFoundationMetrics = _.keyBy(customFoundationMetrics, 'field')
            return _.values(_.assign(queryServiceMetrics, customFoundationMetrics))
        .then (metrics) ->
            return applyOverridesToMetrics(metrics, CONFIG.kpis?.overrides)

    fetchMetrics = ->
        $q.all([
            fetchBaseMetrics(),
            fetchConfiguredMetricDefinitions()
        ]).then ([metrics, definitions]) ->
            fetchCustomFilteredMetricsAsDefinitions(metrics, definitions).then (definitionFilters) ->
                definitions = definitions.concat(definitionFilters)
                metrics = metrics.concat(definitions)
                return metrics
        .then (metrics) ->
            return filterMetricsByWhitelist(metrics)

    getMetrics = ->
        cache ?= fetchMetrics()
        return cache.then((x) -> Utils.copy x)

    applyCurrencyToMetrics: applyCurrencyToMetrics

    fetch: (currency) ->
        getMetrics()
        .then (metrics) ->
            return applyCategoryOverridesToMetrics(metrics, CONFIG.kpis?.categoryOverrides)
        .then (metrics) ->
            return applyCurrencyToMetrics(metrics, currency)



module.service 'HourProperty', ($q, CONFIG, Hierarchy) -> fetch: -> $q.when do ->
    return null if not CONFIG.flags?.showHourDimension
    property = 'transactions.timestamp__hour'
    id:    property
    label: 'Hour'
    sort: {field:property, order:1}


module.service 'CalendarProperties', (DataDescriptors) -> fetch: ->
    DataDescriptors.fetch().then (descriptors) ->
        return null if not descriptors.calendar
        hasQuarter = !!_.find descriptors.calendar, (x) -> x.name is 'quarter_label'
        hasSeason  = !!_.find descriptors.calendar, (x) -> x.name is 'season_label'
        return _.compact([
            {
                column: "timestamp"
                group:  "calendar"
                id:     "calendar.timestamp"
                label:  "Day"
                plural: "Days"
                table:  "calendar"
            }
            {
                column: "week"
                group:  "calendar"
                id:     "calendar.week"
                label:  "Week"
                plural: "Weeks"
                table:  "calendar"
            }
            {
                column: "month_label"
                group:  "calendar"
                id:     "calendar.month_label"
                label:  "Month"
                plural: "Months"
                table:  "calendar"
            }
            if hasQuarter then {
                group:  "calendar"
                id:     "calendar.quarter_label"
                label:  "Quarter"
                plural: "Quarters"
                table:  "calendar"
                column: "quarter_label"
            }
            if hasSeason then {
                column: "season"
                group:  "calendar"
                id:     "calendar.season_label"
                label:  "Season (Calendar)"
                plural: "Season (Calendar)"
                table:  "calendar"
            }
            {
                column: "year"
                group:  "calendar"
                id:     "calendar.year"
                label:  "Year"
                plural: "Years"
                table:  "calendar"
            }
            {
                column: "period_label"
                group:  "calendar"
                id:     "calendar_periods.period_label"
                label:  "Calendar Period"
                plural: "Calendar Periods"
                table:  "calendar_periods"
            }
        ])
